import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from 'react-router-dom';
import { applyCookie } from '../../utils/cookies';

import {
  AUTHENTICATE_CODE,
} from '../../utils/api';

const RedirectPage = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState(false);
  useEffect(() => {
    async function authenticateCode() {
      const { search } = location;
      const response = await AUTHENTICATE_CODE(queryString.parse(search).code);
      if (response.accessToken
        && response.refreshToken && response.accessTokenTTLMS && response.acquiredAtMS) {
        const timeExpiring = response.acquiredAtMS + response.accessTokenTTLMS;
        applyCookie('token', JSON.stringify(response), timeExpiring);
        setAuth(true);
      }
      setLoading(false);
    }
    authenticateCode();
  }, []);

  if (!loading) {
    return (
      auth ? <Redirect to="/main" /> : <Redirect to="/" />
    );
  }

  return (
    <div className="page flex-column">
      <div className="margin-auto text-align-center">
        <CircularProgress size={100} />
        <p className="margin-top-20">Validating your Box credentials...</p>
      </div>
    </div>
  );
};

RedirectPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default RedirectPage;
