import React from "react";

import { Line } from "react-chartjs-2";

const ChartLoanCompleted = ({ labels, chartData, maxY }) => {
  const loanCompletedChartData = {
    labels: labels,
    datasets: [
      {
        label: "Loan completed",
        data: chartData,
        fill: false,
        backgroundColor: "#E36725",
        borderColor: "#E69C75",
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: maxY,
      },
    },
  };
  return (
    <div className="content-card-half-width">
      <Line data={loanCompletedChartData} options={chartOptions} />
    </div>
  );
};

export default ChartLoanCompleted;
