import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const ConfirmModal = ({ processing, completed, closeModal, operation, operationName, contentMessage }) => {
  return (
    <div className="modal detailModal">
      <div className="modal-content-width-20p flex-column">
        {/* Header */}
        <div className="margin-bottom-30 flex-row justify-content-space-between">
          <h1>Are you sure?</h1>
        </div>

        {/* Content */}
        {!processing && !completed && (
          <div>
            <div>
              <h2>{contentMessage}</h2>
            </div>

            <div className="flex-row justify-content-space-evenly">
              <button
                className={`${operationName === "Delete" ? "button-danger" : "background-blue color-white"} cursor-pointer margin-top-20 padding-left-30 padding-right-30 padding-top-10 padding-bottom-10`}
                onClick={operation}
              >
                {operationName}
              </button>
              <button
                className="cursor-pointer margin-top-20 padding-left-30 padding-right-30 padding-top-10 padding-bottom-10"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {/* Loading UI */}
        {processing && !completed && (
          <div className="text-align-center">
            <CircularProgress size={60} />
          </div>
        )}
        {/* Completed UI */}
        {!processing && completed && (
          <div className="text-align-center">
            <h3 className="margin-bottom-20">{operationName} task successful!</h3>
            <button
              type="button"
              className="padding-10 cursor-pointer"
              onClick={closeModal}
            >
              Close Modal
            </button>
          </div>
        )}
        {/* Error UI */}
        {processing && completed && (
          <div className="text-align-center">
            <h3 className="margin-bottom-20">
              Error occurred. Please try again.
            </h3>
            <button
              type="button"
              className="padding-10 cursor-pointer"
              onClick={closeModal}
            >
              Close Modal
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmModal;
