import React, { useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircle";
import Tooltip from "@material-ui/core/Tooltip";
import TextFields from "@material-ui/icons/TextFields";
import CircularProgress from "@material-ui/core/CircularProgress";

import CustomerFilesUI from "./CustomerFilesUI";
import ConfirmRemoveLoanModal from "./ConfirmRemoveLoanModal";
import ConfirmRemoveTaskModal from "./ConfirmRemoveTaskModal";
import AddLoanModal from "./AddLoanModal";
import AddTaskModal from "./AddTaskModal";
import DeleteUserModal from "./DeleteUserModal";
import LoanDocumentInfoModal from "./LoanDocumentInfoModal";

import { REMOVE_LOAN_FROM_USER, ADD_TASK, REMOVE_TASK_FROM_USER, UPDATE_USER_PROFILE } from "../../../utils/api";

const normalizePhoneNumber = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
};

const normalizeZip = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 5) {
      return currentValue;
    } else {
      return currentValue.slice(0, 5);
    }
  }
};

const UserDetailModal = ({ setModalOpen, selectedUser, updateUsers, loanConfig }) => {
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedFolderID, setSelectedFolderID] = useState(null);
  const [hoveredTask, setHoveredTask] = useState(null);

  const [filesUIModalOpen, setFilesUIModalOpen] = useState(false);
  const [confirmRemoveLoan, setConfirmRemoveLoan] = useState(false);
  const [confirmRemoveTask, setConfirmRemoveTask] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [addLoanModalOpen, setAddLoanModalOpen] = useState(false);
  const [addTaskModal, setAddTaskModal] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [loanDocumentInfoModalOpen, setLoanDocumentInfoModalOpen] = useState(false);

  const [newPhone, setNewPhone] = useState('');
  const [newAddress, setNewAddress] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newState, setNewState] = useState('');
  const [newZip, setNewZip] = useState('');

  const [updating, setUpdating] = useState(false);
  const [creating, setCreating] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [completed, setCompleted] = useState(false);

  const closeModal = () => {
    setModalOpen(null);
  };

  const closeModals = async () => {
    setConfirmRemoveLoan(false);
    setSelectedLoan(null);
    setConfirmRemoveTask(false);
    setSelectedTask(null);
    setAddTaskModal(false);
    if (completed) {
      await updateUsers();
    }
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  /**
   * Remove loan from user
   */
  const removeLoanFromUser = async () => {
    const payload = {
      boxAppUserID: selectedUser.boxAppUserID,
      cognitoID: selectedUser.cognitoID,
      loanNumber: selectedLoan.LoanNumber,
      loanType: selectedLoan.LoanType,
    };
    setRemoving(true);
    try {
      await REMOVE_LOAN_FROM_USER(payload);
      setRemoving(false);
      setCompleted(true);
    } catch (err) {
      setCompleted(true);
      alert(err);
    }
  };

  /**
   * Remove profile task from user
   */
   const removeTaskFromUser = async () => {
    const payload = {
      boxAppUserID: selectedUser.boxAppUserID,
      cognitoID: selectedUser.cognitoID,
      taskName: selectedTask.Name,
    };
    setRemoving(true);
    try {
      await REMOVE_TASK_FROM_USER(payload);
      setRemoving(false);
      setCompleted(true);
    } catch (err) {
      setCompleted(true);
      alert(err);
    }
  };

  /**
   * Update user profile
   */
  const updateUserProfile = async () => {
    setUpdating(true);
    const addressObj = {
      Address: newAddress,
      City: newCity,
      State: newState,
      Zip: newZip,
    }
    const payload = {
      boxAppUserID: selectedUser.boxAppUserID,
      cognitoID: selectedUser.cognitoID,
      phone: newPhone,
      address: addressObj,
    };
    try {
      await UPDATE_USER_PROFILE(payload);
      setUpdating(false);
      setCompleted(true);
    } catch (err) {
      alert(err);
      setUpdating(false);
    }
  };

  const getAvailableTasks = () => {
    const allTasks = Object.keys(loanConfig.Tasks);
    const userTasks = selectedUser.tasks.map((task) => task.Name);
    return allTasks.filter((task) => !userTasks.includes(task));
  }

  const userInfoEdited = () => {
    return normalizePhoneNumber(newPhone) === normalizePhoneNumber(selectedUser.phone) &&
      newAddress.trim() === selectedUser.address.Address && 
      newCity.trim() === selectedUser.address.City && 
      newState.trim() === selectedUser.address.State && 
      normalizeZip(newZip.trim()) === normalizeZip(selectedUser.address.Zip);
  }

  /**
  * Add profile task to user
  */
  const addTaskToUser = async (taskName) => {
    setCreating(true);
    const payload = {
      boxAppUserID: selectedUser.boxAppUserID,
      cognitoID: selectedUser.cognitoID,
      taskName: taskName,
    };
    try {
      const result = await ADD_TASK(payload);
      console.log(result);
      setCreating(false);
      setCompleted(true);
    } catch (err) {
      setCompleted(true);
      alert(err);
    }
  };

  const openLoanDocumentModal = (task) => {
    console.log(task);
    setSelectedTask(task);
    setLoanDocumentInfoModalOpen(true);
  }

  return (
    <div className="modal detailModal">
      <div className="modal-content-width-35p flex-column">
        {/* modal header */}
        <div className="margin-bottom-20 margin-top-30 padding-left-50 padding-right-50 flex-row justify-content-space-between">
          <h1>{selectedUser.name}</h1>
          <button
            className="cursor-pointer flex-row align-items-center orange"
            onClick={closeModal}
            type="button"
            style={{ borderRadius: 100 }}
          >
            <CloseIcon />
          </button>
        </div>

        {/* modal content */}
        <div>
          <div className="padding-left-50 padding-right-50 padding-bottom-10 flex-column">
            <h4>Email:</h4>
            <h4>{selectedUser.email}</h4>
          </div>
          
          <div className="padding-left-50 padding-right-50 padding-bottom-10 flex-column">
            <h4>Phone number:</h4>
            {!editMode &&
              (<h4>{normalizePhoneNumber(selectedUser.phone)}</h4>)
            }
            {editMode && (
              <input 
                style={{ width:"120px" }} 
                placeholder='Phone number'
                value={normalizePhoneNumber(newPhone)}
                onChange={(e) =>{ setNewPhone(normalizePhoneNumber(e.target.value)) }}
              />
            )}
          </div>

          <div className="padding-left-50 padding-right-50 padding-bottom-20 flex-column">
            <h4>Address:</h4>
            {!editMode && (
              <>
                <h4>{selectedUser.address.Address}</h4>
                <h4>
                  {selectedUser.address.City}
                  {selectedUser.address.City.length === 0 ? "" : ", "}
                  {selectedUser.address.State}
                  {selectedUser.address.State.length === 0 ? "" : ", "}
                  {selectedUser.address.Zip}
                </h4>
              </>
            )}
            {editMode && (
              <>
                <input 
                  style={{ width:"376px" }} 
                  placeholder="Address" 
                  value={newAddress}
                  onChange={(e)=>{setNewAddress(e.target.value)}}
                />
                <div className="flex-row">
                  <input 
                    style={{ width:"200px" }} 
                    className="margin-right-5" 
                    placeholder="City"
                    value={newCity}
                    onChange={(e)=>{setNewCity(e.target.value)}}
                  />
                  <input 
                    style={{ width:"95px" }} 
                    className="margin-right-5" 
                    placeholder="State"
                    value={newState}
                    onChange={(e)=>{setNewState(e.target.value)}}
                  />
                  <input 
                    style={{ width:"55px" }} 
                    placeholder="Zip code"
                    value={normalizeZip(newZip)}
                    onChange={(e)=>{setNewZip(e.target.value)}}
                  />
                </div>
              </>
            )}
            { editMode && (
              <div>
                <button 
                  disabled={ userInfoEdited() }
                  className={`${userInfoEdited() ? "background-light-grey" : "background-blue"} cursor-pointer color-white margin-top-10 padding-left-20 padding-right-20 padding-top-5 padding-bottom-5`}
                  onClick={(e)=>{
                    e.preventDefault();
                    updateUserProfile();
                    setEditMode(false);
                    updateUsers();
                  }}
                >
                  Save
                </button>
              </div>
            )}
          </div>

          <div className="padding-left-50 padding-right-50 padding-top-10 padding-bottom-10 flex-row justify-content-space-between">
            <div>
              <h2>Loans</h2>
              {selectedUser.loans.length > 0 &&
                selectedUser.loans.map((loan) => {
                  return (
                    <div
                      key={selectedUser.name + loan.LoanType}
                      className="margin-top-10 margin-bottom-20"
                    >
                      {/* Loan type */}
                      <Tooltip
                        title={
                          <div>
                            <h2>Loan#: {loan.LoanNumber}</h2>
                            <h2>{`Created: ${new Date(loan.Timestamp).getFullYear()}-${
                                String(new Date(loan.Timestamp).getMonth() + 1).padStart(2, "0")}-${
                                String(new Date(loan.Timestamp).getDate()).padStart(2, "0")}`}
                            </h2>
                          </div>
                        }
                      >
                        <h3 className="display-inline">{loan.LoanType}</h3>
                      </Tooltip>
                      
                      {/* remove loan button */}
                      {editMode &&
                        <Tooltip
                          title={<h2>Delete {loan.LoanType}</h2>}
                        >
                          <DeleteIcon 
                            className="margin-left-5 color-danger cursor-pointer display-inline-flex vertical-align-sub"
                            fontSize="small" 
                            onClick={(e) => {
                              e.preventDefault();
                              setConfirmRemoveLoan(true);
                              setSelectedLoan(loan);
                            }}
                          />
                        </Tooltip>
                      }
                      
                      {/* loan tasks */}
                      <ul>
                        {loan.LoanTasks.map((task) => {
                          return (
                            <li 
                              key={selectedUser.name + loan.LoanType + task.Name}
                              onMouseEnter={()=>{setHoveredTask(loan.LoanType + task.Name)}}
                              onMouseLeave={()=>{setHoveredTask(null)}}
                            >
                              <input type="checkbox" checked={task.Completed} disabled />
                              {task.Name}
                              {!editMode && task.Completed && hoveredTask === loan.LoanType + task.Name ? 
                                <Tooltip title={<h2>Extracted text</h2>}>
                                  <TextFields 
                                    className="margin-left-5 color-blue cursor-pointer display-inline-flex vertical-align-sub"
                                    fontSize="small" 
                                    onClick={()=>{openLoanDocumentModal(task)}}
                                  /> 
                                </Tooltip> : null
                              }
                            </li>
                          );
                        })}
                      </ul>
                      {/* files button */}
                      <button
                        className="cursor-pointer background-blue color-white margin-right-20 margin-top-5 padding-left-20 padding-right-20 padding-top-5 padding-bottom-5"
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedFolderID(loan.BoxFolderID);
                          setFilesUIModalOpen(true);
                        }}
                      >
                        View files
                      </button>
                    </div>
                  );
                })}
              {selectedUser.loans.length === 0 && <h4>No loan assigned.</h4>}
            </div>

            {/* profile tasks */}
            <div>
              <h2 className="display-inline">Profile Tasks</h2>
              {/* add profile task button */}
              {editMode && (selectedUser.tasks.length < Object.keys(loanConfig.Tasks).length) &&
                <Tooltip
                  title={<h2>Add profile task</h2>}
                >
                  <AddIcon 
                    className="margin-left-10 color-blue cursor-pointer display-inline-flex vertical-align-sub"
                    onClick={(e) => {
                      e.preventDefault();
                      setAddTaskModal(true);
                    }}
                  />
                </Tooltip>
              }
              {editMode && (selectedUser.tasks.length === Object.keys(loanConfig.Tasks).length) &&
                <Tooltip
                  title={<h2>Profile task full</h2>}
                >
                  <AddIcon 
                    className="margin-left-10 color-light-grey display-inline-flex vertical-align-sub"
                  />
                </Tooltip>
              }
              <ul className="margin-top-10">
                {selectedUser.tasks.map((task) => {
                  return (
                    <li 
                      key={selectedUser.name + task.Name}
                      onMouseEnter={()=>{setHoveredTask(task.Name)}}
                      onMouseLeave={()=>{setHoveredTask(null)}}
                    >
                      <input type="checkbox" checked={task.Completed} disabled />
                      <span className="margin-right-5">{task.Name}</span>
                      {!editMode && task.Completed && hoveredTask === task.Name ? 
                        <Tooltip title={<h2>Extracted text</h2>}>
                          <TextFields 
                            className="margin-left-5 color-blue cursor-pointer display-inline-flex vertical-align-sub"
                            fontSize="small" 
                            onClick={()=>{openLoanDocumentModal(task)}}
                          /> 
                        </Tooltip> : null
                      }
                      {editMode && 
                        <Tooltip
                          title={<h2>Delete {task.Name}</h2>}
                        >
                          <DeleteIcon 
                            fontSize="small" 
                            className="margin-left-5 color-danger cursor-pointer display-inline-flex vertical-align-sub"
                            onClick={(e) => {
                              e.preventDefault();
                              setConfirmRemoveTask(true);
                              setSelectedTask(task);
                            }}
                          />
                        </Tooltip>
                      }
                    </li>
                  );
                })}
              </ul>
              {/* files button */}
              <button
                className="cursor-pointer background-blue color-white margin-right-20 margin-top-5 padding-left-20 padding-right-20 padding-top-5 padding-bottom-5"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedFolderID(selectedUser.personalFolderID);
                  setFilesUIModalOpen(true);
                }}
              >
                View Files
              </button>
            </div>
          </div>

          {/* buttons */}
          <div className="padding-left-50 padding-right-50 padding-top-10 padding-bottom-10 flex-column justify-content-space-evenly">
            <button
              className="blue margin-top-5 padding-left-50 padding-right-50 padding-top-10 padding-bottom-10 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                setAddLoanModalOpen(true);
              }}
            >
              <h3>Add loan</h3>
            </button>
            <button
              className="red margin-top-5 padding-left-50 padding-right-50 padding-top-10 padding-bottom-10 cursor-pointer"
              onClick={() => {
                toggleEditMode();
                setNewPhone(selectedUser.phone)
                setNewAddress(selectedUser.address.Address);
                setNewCity(selectedUser.address.City);
                setNewState(selectedUser.address.State);
                setNewZip(selectedUser.address.Zip);
              }}
            >
              <h3>{editMode ? "Exit edit mode" : "Edit mode"}</h3>
            </button>
            {editMode && (
              <button
                className="red margin-top-5 padding-left-50 padding-right-50 padding-top-10 padding-bottom-10 cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  setDeleteUserModalOpen(true);
                }}
              >
                <h3>Delete user</h3>
              </button>
            )}
            <button
              className="margin-top-5 padding-left-50 padding-right-50 padding-top-10 padding-bottom-10 cursor-pointer"
              onClick={closeModal}
            >
              <h3>Done</h3>
            </button>
          </div>
        </div>
        {updating && (
          <div className="text-align-center center">
            <CircularProgress size={120} />
          </div>
        )}

        {filesUIModalOpen && (
          <CustomerFilesUI
            setModalOpen={setFilesUIModalOpen}
            folderID={selectedFolderID}
          />
        )}
        {confirmRemoveLoan && (
          <ConfirmRemoveLoanModal
            closeModal={closeModals}
            operation={removeLoanFromUser}
            selectedUser={selectedUser}
            deleting={removing}
            completed={completed}
          />
        )}
        {addTaskModal && (
          <AddTaskModal 
            closeModal={closeModals}
            operation={addTaskToUser}
            selectedUser={selectedUser}
            availableTasks={getAvailableTasks()}
            creating={creating}
            completed={completed}
          />
        )}
        {confirmRemoveTask && (
          <ConfirmRemoveTaskModal
            closeModal={closeModals}
            operation={removeTaskFromUser}
            selectedUser={selectedTask}
            deleting={removing}
            completed={completed}
          />
        )}
        <AddLoanModal
          modalOpen={addLoanModalOpen}
          selectedUser={selectedUser}
          setModalOpen={setAddLoanModalOpen}
          updateUsers={updateUsers}
          loanConfig={loanConfig}
        />
        <DeleteUserModal
          modalOpen={deleteUserModalOpen}
          setModalOpen={setDeleteUserModalOpen}
          selectedUser={selectedUser}
          updateUsers={updateUsers}
        />
        {loanDocumentInfoModalOpen && (
          <LoanDocumentInfoModal 
            modalOpen={setLoanDocumentInfoModalOpen}
            fileID={selectedTask.BoxFileID}
          />
        )}
      </div>
    </div>
  );
};

UserDetailModal.defaultProps = {
  selectedUser: null,
};

UserDetailModal.propTypes = {
  selectedUser: PropTypes.shape({
    boxAppUserID: PropTypes.string,
    name: PropTypes.string,
    cognitoID: PropTypes.string,
    email: PropTypes.string,
    personalFolderID: PropTypes.string,
    loans: PropTypes.arrayOf(PropTypes.shape({ S: PropTypes.string })),
    tasks: PropTypes.arrayOf(PropTypes.shape({ BOOL: PropTypes.bool })),
  }),
  setModalOpen: PropTypes.func.isRequired,
  updateUsers: PropTypes.func.isRequired,
  loanConfig: PropTypes.shape({
    LoanTypes: PropTypes.shape({}),
    Tasks: PropTypes.shape({}),
  }).isRequired,
};

export default UserDetailModal;
