import React, { useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DELETE_LOAN } from "../../../utils/api";

const DeleteLoanTypeConfirmModal = ({ modalOpen, setModalOpen, updateLoanConfig, selectedLoan }) => {
  const [userInput, setUserInput] = useState("");

  const [deleting, setDeleting] = useState(false);
  const [completed, setCompleted] = useState(false);

  // Close Modal and reset state.
  const closeModal = async () => {
    setModalOpen(false);
    setUserInput("");
    if (completed) {
      await updateLoanConfig();
    }
  };

  /**
   * Input box change handler.
   * @param {*} value user input value
   */
  const userInputHandler = (value) => {
    setUserInput(value);
  };

  /**
   * Delete a specific loan type.
   * @param {*} loanTypeName name of the loan type
   */
  const deleteLoanType = async (loanTypeName) => {
    const payload = { loanType: loanTypeName };
    setDeleting(true);
    try {
      await DELETE_LOAN(payload);
      setDeleting(false);
      setCompleted(true);
    } catch (err) {
      setCompleted(true);
      alert(err);
    }
  };

  return (
    <div
      className="modal detailModal"
      style={{ display: `${modalOpen ? "flex" : "none"}` }}
    >
      <div className="modal-content-width-20p flex-column">
        {/* Header */}
        <div className="margin-bottom-30 flex-row justify-content-space-between">
          <h1>Delete Loan Type</h1>
          <button
            className="cursor-pointer flex-row align-items-center orange"
            onClick={closeModal}
            type="button"
            style={{ borderRadius: 100 }}
          >
            <CloseIcon />
          </button>
        </div>

        {/* Content */}
        {!deleting && !completed && (
          <div>
            <form className="flex-column">
              <h2 className="margin-bottom-20">
                This action <strong>cannot</strong> be undone. This will
                permanently delete the <strong>{selectedLoan}</strong> loan
                type. However, <strong>{selectedLoan}</strong> that are already
                assigned to customers will <strong>not</strong> be deleted.
              </h2>

              <h2 className="margin-bottom-20">
                Please type <strong>{selectedLoan}</strong> to confirm.
              </h2>

              <input
                type="text"
                value={userInput}
                onChange={(e) => {
                  userInputHandler(e.target.value);
                }}
              />

              <button
                className="cursor-pointer button-danger margin-top-20 padding-left-20 padding-right-20 padding-top-10 padding-bottom-10"
                disabled={
                  selectedLoan === null ||
                  userInput.toLocaleLowerCase() !==
                    selectedLoan.toLocaleLowerCase()
                }
                onClick={(e) => {
                  e.preventDefault();
                  deleteLoanType(selectedLoan);
                }}
              >
                <b>I understand the consequences, delete this loan type</b>
              </button>
            </form>
          </div>
        )}

        {/* Loading UI */}
        {deleting && !completed && (
          <div className="text-align-center">
            <CircularProgress size={60} />
          </div>
        )}
        {/* Completed UI */}
        {!deleting && completed && (
          <div className="text-align-center">
            <h3 className="margin-bottom-20">
              Loan type deleted successfully!
            </h3>
            <button
              type="button"
              className="padding-10 cursor-pointer"
              onClick={closeModal}
            >
              Close Modal
            </button>
          </div>
        )}
        {/* Error UI */}
        {deleting && completed && (
          <div className="text-align-center">
            <h3 className="margin-bottom-20">
              Error in deleting a loan type. Please try again.
            </h3>
            <button
              type="button"
              className="padding-10 cursor-pointer"
              onClick={closeModal}
            >
              Close Modal
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

DeleteLoanTypeConfirmModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  updateLoanConfig: PropTypes.func.isRequired
};

export default DeleteLoanTypeConfirmModal;
