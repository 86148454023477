import axios from "axios";

import { getCookie } from "./cookies";

const API_ENDPOINT =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_ENDPOINT
    : process.env.REACT_APP_DEV_ENDPOINT;

const LOGIN_URL = `${API_ENDPOINT}/login`;
const AUTHENTICATE_CODE_URL = `${API_ENDPOINT}/redirect`;
const VALIDATE_TOKEN_URL = `${API_ENDPOINT}/token/validate`;
const USERS_URL = `${API_ENDPOINT}/users`;
const LOAN_URL = `${API_ENDPOINT}/users/loan`;
const TASK_URL = `${API_ENDPOINT}/users/task`;
const LOAN_CONFIG_URL = `${API_ENDPOINT}/loans`;
const LOAN_CRUD_URL = `${API_ENDPOINT}/loan`;
const TASK_CRUD_URL = `${API_ENDPOINT}/task`;
const DOCUMENT_AI_URL = `${API_ENDPOINT}/loans/result`;

/**
 * Extracts access token from cookie.
 */
const extractToken = () => {
  try {
    const token = JSON.parse(getCookie("token")).accessToken;
    return token;
  } catch (err) {
    return "";
  }
};

/**
 * Retrieves Box Login URI from backend.
 */
export const GET_LOGIN_URI = async () => {
  try {
    const response = await axios.get(LOGIN_URL);
    window.location = response.data;
  } catch (err) {
    console.log(err);
  }
};

/**
 * Exchanges code for a box access token
 * @param {string} code code query string from url
 */
export const AUTHENTICATE_CODE = async (code) => {
  try {
    const response = await axios.get(AUTHENTICATE_CODE_URL, {
      params: {
        code,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

/**
 * Validates the token in browser cookie to access page
 */
export const VALIDATE_TOKEN = async () => {
  const token = extractToken();

  try {
    const response = await axios.get(VALIDATE_TOKEN_URL, {
      headers: {
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
};

/**
 * Get all app users from the JWT service account.
 */
export const GET_USERS = async () => {
  const token = extractToken();

  try {
    const response = await axios.get(USERS_URL, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.Items;
  } catch (err) {
    console.log(err);
    return err;
  }
};

/**
 * Create a box and cognito account for a user.
 * @param {object} data keys required: name, email, loanNumber, loanType
 */
export const CREATE_USER = async (data) => {
  const token = extractToken();
  try {
    const response = await axios.post(USERS_URL, data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    throw Error(err.response.data);
  }
};

/**
 * Delete the box and cognito user.
 * @param {object} data keys required: email, boxAppUserID, personalFolderID
 */
export const DELETE_USER = async (data) => {
  const token = extractToken();

  try {
    const response = await axios.delete(USERS_URL, {
      headers: {
        Authorization: token,
      },
      data,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw Error(err.response.data);
  }
};

/**
 * Add loan to a user
 * @param {*} data keys required: boxAppUserID, cognitoID, loanNumber, loanType
 */
export const ADD_LOAN = async (data) => {
  const token = extractToken();

  try {
    const response = await axios.post(LOAN_URL, data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw Error(err.response.data);
  }
};

/**
 * Delete a loan from user
 * @param {*} data keys required: boxAppUserID, cognitoID, loanNumber, loanType
 */
export const REMOVE_LOAN_FROM_USER = async (data) => {
  const token = extractToken();

  try {
    const response = await axios.delete(LOAN_URL, {
      data,
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw Error(err.response.data);
  }
};

/**
 * Add profile task to a user
 * @param {*} data keys required: boxAppUserID, cognitoID, taskName
 */
export const ADD_TASK = async (data) => {
  const token = extractToken();

  try {
    const response = await axios.post(TASK_URL, data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw Error(err.response.data);
  }
};

/**
 * Delete a profile task from user
 * @param {*} data keys required: boxAppUserID, cognitoID, taskName
 */
export const REMOVE_TASK_FROM_USER = async (data) => {
  const token = extractToken();

  try {
    const response = await axios.delete(TASK_URL, {
      data,
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw Error(err.response.data);
  }
};

/**
 * Get loan configuration setup
 */
export const GET_LOAN_CONFIG = async () => {
  try {
    const response = await axios.get(LOAN_CONFIG_URL);
    return response.data;
  } catch (err) {
    console.log(err);
    throw Error(err.response.data);
  }
};

/**
 * Create a loan in the database.
 * @param {object} data task object
 */
export const CREATE_LOAN = async (data) => {
  const token = extractToken();

  try {
    const response = await axios.post(LOAN_CRUD_URL, data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw Error(err.response.data);
  }
};

/**
 * Edit a loan in the database.
 * @param {object} data task object
 */
export const EDIT_LOAN = async (data) => {
  const token = extractToken();

  try {
    const response = await axios.patch(LOAN_CRUD_URL, data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw Error(err.response.data);
  }
};

export const DELETE_LOAN = async (data) => {
  const token = extractToken();

  try {
    const response = await axios.delete(LOAN_CRUD_URL, {
      data,
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw Error(err.response.data);
  }
};

export const CREATE_PROFILE_TASK = async (data) => {
  const token = extractToken();

  try {
    const response = await axios.post(TASK_CRUD_URL, data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw Error(err.response.data);
  }
};

export const EDIT_PROFILE_TASK = async (data) => {
  const token = extractToken();

  try {
    const response = await axios.patch(TASK_CRUD_URL, data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw Error(err.response.data);
  }
};

export const DELETE_PROFILE_TASK = async (data) => {
  const token = extractToken();

  try {
    const response = await axios.delete(TASK_CRUD_URL, {
      data,
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw Error(err.response.data);
  }
};

/**
 * Update user profile.
 */
 export const UPDATE_USER_PROFILE = async (data) => {
  const token = extractToken();
  
  try {
    const response = await axios.patch(USERS_URL, data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw Error(err.response.data);
  }
};

/**
 * Get Document AI Result from Backen by passing in file id
 * @param {string} fileID box file id
 */
 export const GET_DOCUMENT_AI_RESULT = async (fileID) => {
  const token = extractToken();

  try {
    const response = await axios.get(`${DOCUMENT_AI_URL}/${fileID}`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw Error(err.response.data);
  }
};