import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";

const AddTaskModal = ({ closeModal, operation, selectedUser, availableTasks, creating, completed }) => {
  const [selectedTask, setSelectedTask] = useState(availableTasks[0]);

  return (
    <div className="modal">
      <div className="modal-content flex-column">
        <div className="margin-bottom-30 flex-row justify-content-space-between">
          {selectedUser && (
            <h1>{`Add profile task for ${selectedUser.name}`}</h1>
          )}
          <button
            className="cursor-pointer flex-row align-items-center orange"
            onClick={closeModal}
            type="button"
            style={{ borderRadius: 100 }}
          >
            <CloseIcon />
          </button>
        </div>
        {/* Form UI */}
        {!creating && !completed && (
          <form>
            <select value={selectedTask} onChange={(e) => setSelectedTask(e.target.value)}>
              {availableTasks.map((task) => {
                return (
                  <option value={task} key={`${task}`}>
                    {task}
                  </option>
                );
              })}
            </select>
            <button
              className="margin-left-10 background-orange padding-5 color-white cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                operation(selectedTask);
              }}
            >
              Submit
            </button>
          </form>
        )}
        {/* Loading UI */}
        {creating && !completed && (
          <div className="text-align-center">
            <CircularProgress size={60} />
          </div>
        )}
        {/* Completed UI */}
        {!creating && completed && (
          <div className="text-align-center">
            <h3 className="margin-bottom-20">
              Profile task added successfully!
            </h3>
            <button
              type="button"
              className="padding-10 cursor-pointer"
              onClick={closeModal}
            >
              Close Modal
            </button>
          </div>
        )}
        {/* Error UI */}
        {creating && completed && (
          <div className="text-align-center">
            <h3 className="margin-bottom-20">
              Error in adding profile task. Please try again.
            </h3>
            <button
              type="button"
              className="padding-10 cursor-pointer"
              onClick={closeModal}
            >
              Close Modal
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddTaskModal;
