import React, { useEffect, useState } from "react";
import logoSquare from "../../../assets/logo-square.png";
import { getCookie } from "../../../utils/cookies";

const BOX_ROOT_FOLDER =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BOX_ROOT_FOLDER_PROD
    : process.env.REACT_APP_BOX_ROOT_FOLDER_DEV;

const Files = () => {
  const [jsLoaded, setJsLoaded] = useState(false);
  const [cssLoaded, setCssLoaded] = useState(false);

  const jsLoad = () => {
    setJsLoaded(true);
  };
  const cssLoad = () => {
    setCssLoaded(true);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://cdn01.boxcdn.net/platform/elements/11.0.2/en-US/explorer.js";
    script.async = true;
    script.onload = jsLoad;
    document.head.appendChild(script);

    const link = document.createElement("link");
    link.href =
      "https://cdn01.boxcdn.net/platform/elements/11.0.2/en-US/explorer.css";
    link.rel = "stylesheet";
    link.onload = cssLoad;
    document.head.appendChild(link);
  }, []);

  useEffect(() => {
    if (jsLoaded && cssLoaded) {
      const { Box } = window;
      const { ContentExplorer } = Box;
      const contentExplorer = new ContentExplorer();

      const folderId = BOX_ROOT_FOLDER;
      const { accessToken } = JSON.parse(getCookie("token"));

      contentExplorer.show(folderId, accessToken, {
        container: ".container",
        logoUrl: logoSquare,
        canUpload: false,
        canCreateNewFolder: false,
        canRename: false,
        canDelete: false,
      });
    }
  }, [jsLoaded, cssLoaded]);

  return (
    <div className="content-card-full-width full-height">
      <div className="container full-height" />
    </div>
  );
};

export default Files;
