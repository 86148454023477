import React, { useState } from "react";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CreateLoanModal from "./CreateLoanModal";
import EditLoanModal from "./EditLoanModal";
import DeleteLoanTypeConfirmModal from "./DeleteLoanTypeConfirmModal";

const ManageLoanPage = ({ loanConfig, updateLoanConfig }) => {
  const [createLoanModalOpen, setCreateLoanModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteLoanModalOpen, setDeleteLoanModalOpen] = useState(false);

  const [selectedLoan, setSelectedLoan] = useState(null);

  const selectLoan = (loanType) => {
    if (loanType === selectedLoan) {
      setSelectedLoan(null);
      return;
    }
    setSelectedLoan(loanType);
  };

  // Render Loan Types in LoanConfig
  const listLoans = () => (
    <TableContainer>
      <Table aria-label="Loan Types Table">
        <TableHead>
          <TableRow>
            <TableCell>
              <h4>
                <b>Loan Type Name</b>
              </h4>
            </TableCell>
          </TableRow>
        </TableHead>
        {loanConfig && loanConfig.LoanTypes && (
          <TableBody>
            {Object.keys(loanConfig.LoanTypes).map((loanType) => {
              const selectedRow = loanType === selectedLoan;
              return (
                <TableRow
                  key={loanType}
                  onClick={() => selectLoan(loanType)}
                  className={`${
                    selectedRow && "background-orange"
                  } cursor-pointer`}
                >
                  <TableCell>
                    <span className={`${selectedRow && "color-white"}`}>
                      {loanType}
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );

  const listTasks = () => {
    if (selectedLoan) {
      const { Tasks } = loanConfig.LoanTypes[selectedLoan];
      return (
        <>
          {Tasks && Tasks.map((task) => {
              const { Name, UserAction, ToExtract } = task;
              return (
                <div key={selectedLoan + Name} className="margin-bottom-10">
                  <p>{`Name: ${Name}`}</p>
                  <div className="flex-row">
                    <p>UserAction: </p>
                    <input type="checkbox" checked={UserAction} disabled />
                  </div>
                  <div className="flex-row">
                    <p>Extract Document: </p>
                    <input type="checkbox" checked={ToExtract} disabled />
                  </div>
                </div>
              );
            })}

          <button
            type="button"
            className="cursor-pointer margin-right-20 margin-top-20 padding-left-20 padding-right-20 padding-top-10 padding-bottom-10"
            onClick={() => setEditModalOpen(true)}
          >
            Edit
          </button>
          <button
            type="button"
            className="cursor-pointer button-danger margin-top-20 padding-left-20 padding-right-20 padding-top-10 padding-bottom-10"
            onClick={() => setDeleteLoanModalOpen(true)}
          >
            Delete Loan Type
          </button>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <div className="flex-row content-max-width margin-left-auto margin-right-auto margin-top-30">
        {/* Left Body */}
        <div className="flex margin-left-20 margin-right-20 margin-bottom-20">
          <div className="flex-row">
            <h2 className="margin-bottom-30 margin-right-20">Loan Types</h2>
          </div>

          <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
            {listLoans()}
          </div>

          <button
            type="button"
            onClick={() => setCreateLoanModalOpen(true)}
            className="margin-top-20 background-blue padding-left-20 padding-right-20 padding-top-10 padding-bottom-10 cursor-pointer color-white"
          >
            Create Loan Type
          </button>
        </div>
        {/* Right Body */}
        <div className="flex margin-left-20 margin-right-20">
          <h2 className="margin-bottom-30">Loan Tasks</h2>
          {listTasks()}
        </div>
      </div>
      <CreateLoanModal
        modalOpen={createLoanModalOpen}
        setModalOpen={setCreateLoanModalOpen}
        updateLoanConfig={updateLoanConfig}
      />
      {editModalOpen && (
        <EditLoanModal
          modalOpen={editModalOpen}
          setModalOpen={setEditModalOpen}
          updateLoanConfig={updateLoanConfig}
          selectedLoan={selectedLoan}
          selectedLoanTasks={loanConfig.LoanTypes[selectedLoan].Tasks}
        />
      )}
      <DeleteLoanTypeConfirmModal
        modalOpen={deleteLoanModalOpen}
        setModalOpen={setDeleteLoanModalOpen}
        updateLoanConfig={updateLoanConfig}
        selectedLoan={selectedLoan}
      />
    </>
  );
};

ManageLoanPage.defaultProps = {
  loanConfig: null,
};

ManageLoanPage.propTypes = {
  loanConfig: PropTypes.shape({
    LoanTypes: PropTypes.shape({}),
  }),
  updateLoanConfig: PropTypes.func.isRequired,
};

export default ManageLoanPage;
