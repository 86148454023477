import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const IncompletedLoans = ({ data }) => {
  return (
    <div className="content-card-full-width">
      <TableContainer>
        <Table aria-label="Incompleted loans">
          <TableHead>
            <TableRow>
              <TableCell>
                <h2>Incompleted loans</h2>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 &&
              data.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell style={{ padding: "7px 16px" }}>
                      {`${item.userName}'s ${item.loanName} has been incompleted for 
                      ${Math.floor((Date.now() - item.timestamp) / (24 * 60 * 60 * 1000))} days.`}
                    </TableCell>
                  </TableRow>
                );
              })}
            {data.length === 0 && (
              <TableRow>
                <TableCell style={{ padding: "7px 16px", color: "lightgrey" }}>
                  No item to display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default IncompletedLoans;
