import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

const ConfirmRemoveLoanModal = ({ closeModal, operation, deleting, completed }) => {
  return (
    <div className="modal">
      <div className="modal-content-width-20p flex-column">
        {/* Header */}
        <div className="margin-bottom-30 flex-row justify-content-space-between">
          <h1>Are you sure?</h1>
          <button
            className="cursor-pointer flex-row align-items-center orange"
            onClick={closeModal}
            type="button"
            style={{ borderRadius: 100 }}
          >
            <CloseIcon />
          </button>
        </div>

        {!deleting && !completed && (
          <>
            <h3></h3>
            <h3>Removing this loan will also remove the loan folder.</h3>
            <div className="flex-row justify-content-space-around">
              <button
                className="button-danger margin-top-20 margin-right-20 padding-left-20 padding-right-20 padding-top-10 padding-bottom-10 cursor-pointer"
                onClick={() => operation()}
              >
                Remove Loan
              </button>
              <button
                className="margin-top-20 margin-right-20 padding-left-20 padding-right-20 padding-top-10 padding-bottom-10 cursor-pointer"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </>
        )}
        {/* Loading UI */}
        {deleting && !completed && (
          <div className="text-align-center">
            <CircularProgress size={60} />
          </div>
        )}
        {/* Completed UI */}
        {!deleting && completed && (
          <div className="text-align-center">
            <h3 className="margin-bottom-20">Loan removed successfully!</h3>
            <button
              type="button"
              className="padding-10 cursor-pointer"
              onClick={closeModal}
            >
              Close Modal
            </button>
          </div>
        )}
        {/* Error UI */}
        {deleting && completed && (
          <div className="text-align-center">
            <h3 className="margin-bottom-20">
              Error in removing loan. Please try again.
            </h3>
            <button
              type="button"
              className="padding-10 cursor-pointer"
              onClick={closeModal}
            >
              Close Modal
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmRemoveLoanModal;
