import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import LoanFolderUI from "./LoanFolderUI";

import Moment from "react-moment";

const CompletedLoans = ({ data }) => {
  const [selectedLoan, setSelectedLoan] = useState(null)
  const [displayDays, setDisplayDays] = useState(7);

  const setDisplayDays7 = () => {
    setDisplayDays(7);
  };
  const setDisplayDays30 = () => {
    setDisplayDays(30);
  };
  const setDisplayDays365 = () => {
    setDisplayDays(365);
  };

  return (
    <div className="content-card-full-width">
      <TableContainer>
        <Table aria-label="Completed loans">
          <TableHead>
            <TableRow>
              <TableCell>
              <div className="flex-row justify-content-space-between">
                  <h2 className="margin-top-5 margin-bottom-5">Completed loans</h2>
                  <div className="flex-row">

                    <h3 className="margin-5">show: </h3>

                    <h3 
                      className={`margin-5 ${displayDays === 7 ? "" : "default-a color-orange"}`}
                      onClick={setDisplayDays7}
                    >
                      1 week
                    </h3>

                    <div className="divider"></div>

                    <h3
                      className={`margin-5 ${displayDays === 30 ? "" : "default-a color-orange"}`}
                      onClick={setDisplayDays30}
                    >
                      1 month
                    </h3>

                    <div className="divider"></div>

                    <h3
                      className={`margin-5 ${displayDays === 365 ? "" : "default-a color-orange"}`}
                      onClick={setDisplayDays365}
                    >
                      1 year
                    </h3>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 &&
              data.map((item, index) => {
                return Date.now() - item.timestamp <
                displayDays * 24 * 60 * 60 * 1000 ? (
                  <TableRow key={index}>
                    <TableCell style={{ padding: "7px 16px" }}>
                      {`${item.userName} has completed all tasks in `}
                      <span 
                        className="default-a"
                        onClick={() => {setSelectedLoan(item.loanFolderID)}}
                      >
                        {item.loanName}
                      </span>
                      {" "}
                      <Moment fromNow>{item.timestamp}</Moment>.
                    </TableCell>
                  </TableRow>
                ): null;
              })}
            {/* if there are no data to show */}
            {(data.length === 0 || 
              data.filter((item) => {return (Date.now() - item.timestamp < displayDays * 24 * 60 * 60 * 1000);}).length === 0
            ) && (
              <TableRow>
                <TableCell style={{ padding: "7px 16px", color: "lightgrey" }}>
                  No item to display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedLoan && 
        <LoanFolderUI 
          setModalOpen={setSelectedLoan}
          folderID={selectedLoan}
        />
      }
    </div>
  );
};

export default CompletedLoans;
