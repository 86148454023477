import React, { useState } from "react";
import PropTypes from "prop-types";
import MaterialTable from "material-table";

import CreateUserModal from "./CreateUserModal";
import AddLoanModal from "./AddLoanModal";
import DeleteUserModal from "./DeleteUserModal";
import UserDetailModal from "./UserDetailModal";

const TABLE_FONT = {
  cellStyle: {
    fontFamily: "Quicksand, sans-serif",
  },
};

const columns = [
  { title: "Name", field: "name", ...TABLE_FONT },
  { title: "Email", field: "email", ...TABLE_FONT },
  { title: "Loans", field: "loans", ...TABLE_FONT, align: "right" },
  { title: "Tasks Completed", field: "tasks", ...TABLE_FONT, align: "right" },
  { title: "Last Activity", field: "lastActivityTimestampString", ...TABLE_FONT, align: "right" },
];

/**
 * Find timestamp of last activity
 *
 * @param {*} user user object
 * @returns last activity timestamp
 */
const getLastActivityTimestamp = (user) => {
  let lastTimestamp = user.Timestamp;
  user.Loans.forEach((loan) => {
    lastTimestamp = Math.max(lastTimestamp, loan.Timestamp);
    loan.LoanTasks.forEach((task) => {
      if (task.Timestamp) {
        lastTimestamp = Math.max(lastTimestamp, task.Timestamp);
      }
    });
  });
  user.Tasks.forEach((task) => {
    if (task.Timestamp) {
      lastTimestamp = Math.max(lastTimestamp, task.Timestamp);
    }
  });
  return lastTimestamp;
};

const ManageUsersPage = ({ loanConfig, users, updateUsers }) => {
  const [selectedUser, setSelectedUser] = useState(null);

  const [userModalOpen, setUserModalOpen] = useState(false);
  const [loanModalOpen, setLoanModalOpen] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);

  /**
   * Parse user data for material table
   *
   * @returns simplified user data
   */
  const simplifyUserInfo = () => {
    if (users.length > 0) {
      const userInfo = users.map((user) => ({
        name: user.Name,
        loans: user.Loans.length,
        tasks: `${user.Tasks.filter((task) => task.Completed).length} / ${
          user.Tasks.length
        }`,
        email: user.Email,
        phone: user.Phone,
        address: user.Address,
        lastActivityTimestamp: getLastActivityTimestamp(user),
        // createdAt: <Moment format="YYYY-MM-DD">{user.Timestamp}</Moment>,
        lastActivityTimestampString: `${new Date(getLastActivityTimestamp(user)).getFullYear()}-${String(new Date(getLastActivityTimestamp(user)).getMonth() + 1).padStart(2, "0")}-${String(new Date(getLastActivityTimestamp(user)).getDate()).padStart(2, "0")}`,
      }));
      userInfo.sort(
        (a, b) => b.lastActivityTimestamp - a.lastActivityTimestamp
      );
      return userInfo;
    }
  };

  /**
   * Parse data for user detail prop
   *
   * @returns detail user info object
   */
  const fullUserDetail = () => {
    const { email } = selectedUser;
    const user = users.find((u) => u.Email === email);
    if (user) {
      return {
        name: user.Name,
        cognitoID: user.CognitoID,
        boxAppUserID: user.BoxAppUserID,
        loans: user.Loans,
        tasks: user.Tasks,
        email: user.Email,
        phone: user.Phone,
        address: user.Address,
        personalFolderID: user.PersonalFolder,
        createdAt: user.Timestamp,
      };
    }
    return null;
  };

  return (
    <>
      <div className="margin-left-40 margin-right-40 margin-top-60 padding-left-20 padding-right-20 text-align-center margin-auto content-max-width">
        <MaterialTable
          title="Customers"
          data={simplifyUserInfo()}
          columns={columns}
          options={{
            headerStyle: { fontFamily: "Montserrat, sans-serif" },
            paging: true,
            pageSize: 10,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [10, 20, 50],
          }}
          actions={[
            {
              icon: "add",
              tooltip: "Create Customer",
              isFreeAction: true,
              onClick: () => {
                setUserModalOpen(true);
              },
            },
          ]}
          onRowClick={(event, rowData) => {
            setSelectedUser(rowData);
          }}
        />
      </div>
      <CreateUserModal
        modalOpen={userModalOpen}
        setModalOpen={setUserModalOpen}
        updateUsers={updateUsers}
        loanConfig={loanConfig}
      />
      <AddLoanModal
        modalOpen={loanModalOpen}
        setModalOpen={setLoanModalOpen}
        selectedUser={selectedUser}
        updateUsers={updateUsers}
        loanConfig={loanConfig}
      />
      <DeleteUserModal
        modalOpen={deleteUserModalOpen}
        setModalOpen={setDeleteUserModalOpen}
        selectedUser={selectedUser}
        updateUsers={updateUsers}
      />
      {selectedUser && (
        <UserDetailModal
          setModalOpen={setSelectedUser}
          selectedUser={fullUserDetail(selectedUser)}
          updateUsers={updateUsers}
          loanConfig={loanConfig}
        />
      )}
    </>
  );
};

ManageUsersPage.defaultProps = {
  loanConfig: null,
};

ManageUsersPage.propTypes = {
  loanConfig: PropTypes.shape({}),
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateUsers: PropTypes.func.isRequired,
};

export default ManageUsersPage;
