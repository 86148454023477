import React from 'react';
import Footer from '../../components/Footer';
import {
  GET_LOGIN_URI,
} from '../../utils/api';

import logo from '../../assets/logo-whiteorange.png';

/**
 * Index page of Manage Users
 */
const IndexPage = () => (
  <>
    <div className="index-background flex-column" style={{ height: '100vh' }}>
      {/* Header */}
      <header className="flex-row padding-left-30 padding-right-30 background-purple margin-bottom-20">
        <div className="flex-row align-items-center margin-auto justify-content-space-between content-max-width" style={{ height: 'inherit' }}>
          <img src={logo} className="margin-top-10 margin-top-auto margin-bottom-auto margin-right-40" style={{ height: 'calc(100% - 40px)' }} alt="Reva Solutions Logo" />
        </div>
      </header>
      {/* Body */}
      <div className="margin-left-auto margin-right-auto content-max-width">
        <h1 className="margin-top-30 margin-bottom-60">Welcome to loan management portal.</h1>
        <h3 className="margin-top-30">Use your box credential to login:</h3>
        <button onClick={GET_LOGIN_URI} className="background-orange cursor-pointer padding-left-50 padding-right-50 padding-top-30 padding-bottom-30 margin-top-10 color-white" type="button">
          <h3>Login</h3>
        </button>
        <p className="margin-top-40">If you don't have a box account, contact your account administrator.</p>
        <p>Independent user? <a className="default-a" href="https://www.box.com/pricing/individual" target="_blank" rel = "noopener noreferrer">Sign up with box</a></p>
      </div>
    </div>
    <Footer />
  </>
);

export default IndexPage;
