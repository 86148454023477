import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DELETE_USER } from '../../../utils/api';

const DeleteUserModal = ({
  modalOpen, setModalOpen, selectedUser, updateUsers,
}) => {
  const [deleting, setDeleting] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [deleteResult, setDeleteResult] = useState({});

  /**
   * Delete user by making an api call.
   */
  const deleteUser = async (e) => {
    e.preventDefault();
    setDeleting(true);
    const payload = {
      cognitoID: selectedUser.cognitoID,
      email: selectedUser.email,
      boxAppUserID: selectedUser.boxAppUserID,
      personalFolderID: selectedUser.personalFolderID,
    };
    try {
      const result = await DELETE_USER(payload);
      setDeleteResult(result);
      setCompleted(true);
      setDeleting(false);
    } catch (err) {
      alert(err);
      setDeleting(false);
      setCompleted(false);
    }
  };

  /**
   * Close modal and reset state
   */
  const closeModal = async () => {
    setDeleting(false);
    setCompleted(false);
    setModalOpen(false);
    if (completed) {
      await updateUsers();
    }
  };

  /**
   * Checks if all key/value pairs from delete result are true.
   */
  const deleteSuccess = () => {
    let success = true;
    Object.values(deleteResult).forEach((boolean) => {
      if (!boolean) success = false;
    });
    return success;
  };

  return (
    <div className="modal" style={{ display: `${modalOpen ? 'flex' : 'none'}` }}>
      <div className="modal-content flex-column">
        <div className="margin-bottom-30 flex-row justify-content-space-between">
          <h1>Delete Customer</h1>
          <button className="cursor-pointer flex-row align-items-center orange" onClick={closeModal} type="button" style={{ borderRadius: 100 }}>
            <CloseIcon />
          </button>
        </div>

        {/* Confirmation UI */}
        {
          !deleting && !completed && selectedUser && (
            <div>
              <p className="margin-bottom-20">
                <span>{'Are you sure you want to delete '}</span>
                <b>{`${selectedUser.name}`}</b>
                <span>{' ('}</span>
                <b>{`${selectedUser.email}`}</b>
                <span>)</span>
                <span>?</span>
              </p>

              <p className="margin-bottom-20">Loan folders will not be deleted and the customer will not be able to access the files anymore.</p>

              <button
                className="padding-left-20 padding-right-20 padding-top-10 padding-bottom-10 cursor-pointer button background-blue color-white"
                type="button"
                onClick={deleteUser}
              >
                Confirm Delete
              </button>
            </div>
          )
        }
        {/* Deleting UI */}
        {
          deleting && !completed && (
            <div className="text-align-center">
              <CircularProgress size={60} />
            </div>
          )
        }
        {/* Completed UI */}
        {
          !deleting && completed && (
            <div className="text-align-center">
              {deleteSuccess() ? (
                <h3 className="margin-bottom-20">
                  Customer deleted successfully!
                </h3>
              ) : (
                <h3 className="margin-bottom-20">
                  Customer deleted successfully!!
                </h3>
              )}

              <button type="button" className="padding-10 cursor-pointer" onClick={closeModal}>Close Modal</button>
            </div>
          )
        }
      </div>
    </div>
  );
};

DeleteUserModal.defaultProps = {
  selectedUser: null,
  loanConfig: null,
};

DeleteUserModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  selectedUser: PropTypes.shape({
    boxAppUserID: PropTypes.string,
    name: PropTypes.string,
    cognitoID: PropTypes.string,
    email: PropTypes.string,
    personalFolderID: PropTypes.string,
  }),
  setModalOpen: PropTypes.func.isRequired,
  updateUsers: PropTypes.func.isRequired,
  loanConfig: PropTypes.shape({
    LoanTypes: PropTypes.shape({}),
  }),
};

export default DeleteUserModal;
