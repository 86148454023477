import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmModal from "./ConfirmModal";
import { EDIT_PROFILE_TASK } from "../../../utils/api";

// DOM Id's
const TASK_NAME = "taskNameId";
const TASK_TO_EXTRACT = "taskToExtractId";

const EditTaskModal = ({
  selectedTask,
  selectedTaskToExtract,
  setOnToggleEdit,
  updateLoanConfig,
}) => {
  const [taskName, setTaskName] = useState(selectedTask);
  const [toExtract, setToExtract] = useState(selectedTaskToExtract);

  const [onEditConfirm, setOnEditConfirm] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [completed, setCompleted] = useState(false);

  const closeModal = async () => {
    setOnToggleEdit(false);
    setOnEditConfirm(false);
    if (completed) {
      await updateLoanConfig();
    }
  };

  const toggleToExtract = (event) => {
    setToExtract(event.target.checked);
  };

  const editTask = async () => {
    const payload = {
      selectedTask: selectedTask,
      newName: taskName,
      toExtract: toExtract,
    };
    setProcessing(true);
    try {
      await EDIT_PROFILE_TASK(payload);
      setProcessing(false);
      setCompleted(true);
    } catch (err) {
      setCompleted(true);
      alert(err);
    }
  };

  return (
    <div className="modal detailModal">
      <div className="modal-content-width-20p flex-column">
        {/* Header */}
        <div className="margin-bottom-30 flex-row justify-content-space-between">
          <h1>Edit Profile Task</h1>
          <button
            className="cursor-pointer flex-row align-items-center orange"
            onClick={closeModal}
            type="button"
            style={{ borderRadius: 100 }}
          >
            <CloseIcon />
          </button>
        </div>

        {/* Form UI */}
        {!processing && !completed && (
          <div>
            <form className="flex-column">
              <label className="margin-bottom-20 flex-row" htmlFor={TASK_NAME}>
                <p style={{ width: 150 }}>Task Name:</p>
                <input
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  id={TASK_NAME}
                />
              </label>
              <label
                className="margin-bottom-20 flex-row margin-right-40"
                htmlFor={TASK_TO_EXTRACT}
              >
                <p style={{ width: 150 }}>Extract Document:</p>
                <input
                  type="checkbox"
                  value={toExtract}
                  onChange={(e) => toggleToExtract(e)}
                  id={TASK_TO_EXTRACT}
                  checked={toExtract}
                />
              </label>
              <input
                className="padding-left-20 padding-right-20 padding-top-10 padding-bottom-10 cursor-pointer button background-blue color-white text-align-center"
                style={{ width: 125 }}
                value="Edit Task"
                onClick={() => {
                  setOnEditConfirm(true);
                }}
                readOnly
              />
            </form>
          </div>
        )}

        {/* Confirm */}
        {onEditConfirm && (
          <ConfirmModal
            processing={processing}
            completed={completed}
            closeModal={closeModal}
            operation={editTask}
            operationName={"Edit"}
            contentMessage={[
              "About to edit ",
              <strong>{selectedTask}</strong>,
              ". Tasks that are already assigned will ",
              <strong>not</strong>,
              " be changed.",
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default EditTaskModal;
