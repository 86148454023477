import React, { useState, useEffect } from "react";

import NewUploads from "./NewUploads";
import CompletedLoans from "./CompletedLoans";
import IncompletedLoans from "./IncompletedLoans";
import ChartLoanStarted from "./ChartLoanStarted";
import ChartLoanCompleted from "./ChartLoanCompleted";

// /**
//  * NOT IN USE
//  *
//  * Helper function to parse new uploads
//  *
//  * @param {*} data user data
//  * @returns [{ name, fileID, type, timestamp }]
//  */
// const getNewUploads = (data) => {
//   let newItems = [];
//   data.forEach((user) => {
//     user.Loans.forEach((loan) => {
//       loan.LoanTasks.forEach((task) => {
//         if (task.BoxFileID) {
//           newItems = [
//             ...newItems,
//             {
//               name: user.Name,
//               fileID: task.BoxFileID,
//               type: loan.LoanType,
//               timestamp: task.Timestamp,
//             },
//           ];
//         }
//       });
//     });
//     user.Tasks.forEach((task) => {
//       if (task.BoxFileID) {
//         newItems = [
//           ...newItems,
//           {
//             name: user.Name,
//             fileID: task.BoxFileID,
//             type: "Profile",
//             timestamp: task.Timestamp,
//           },
//         ];
//       }
//     });
//   });
//   newItems.sort((first, second) => second.timestamp - first.timestamp);
//   return newItems;
// };

// /**
//  * NOT IN USE
//  *
//  * Helper function to parse completed loans
//  *
//  * @param {*} data user data
//  * @returns [{ userName, loanName, timestamp }]
//  */
// const getCompletedLoans = (data) => {
//   let completedLoans = [];
//   data.forEach((user) => {
//     user.Loans.forEach((loan) => {
//       let completed = [];
//       let lastTimestamp = Date.now();
//       loan.LoanTasks.forEach((task) => {
//         completed = [...completed, task.Completed];
//         lastTimestamp = Math.min(task.Timestamp);
//       });
//       if (completed.every((e) => e === true)) {
//         completedLoans = [
//           ...completedLoans,
//           {
//             userName: user.Name,
//             loanName: loan.LoanType,
//             timestamp: lastTimestamp,
//           },
//         ];
//       }
//     });
//   });
//   completedLoans.sort((first, second) => second.timestamp - first.timestamp);
//   return completedLoans;
// };

// /**
//  * NOT IN USE
//  *
//  * Helper function to parse incompleted loans
//  *
//  * @param {*} data user data
//  * @returns [{ userName, loanName }]
//  */
// const getIncompletedLoans = (data) => {
//   let InactiveLoans = [];
//   data.forEach((user) => {
//     user.Loans.forEach((loan) => {
//       if (
//         !loan.LoanTasks.map((task) => task.Completed).every((e) => e === true)
//       ) {
//         InactiveLoans = [
//           ...InactiveLoans,
//           { userName: user.Name, loanName: loan.LoanType },
//         ];
//       }
//     });
//   });
//   return InactiveLoans;
// };

// /**
//  * NOT IN USE
//  *
//  * Helper function to find the number of loans completed for the past 365 days
//  *
//  * @param {*} data user data
//  * @returns array of loans completed for the past 365 days (January always in position 0)
//  */
// const findCompletedLoanCountByMonth = (data) => {
//   const completedLoanCountByMonth = new Array(12).fill(0);
//   data.forEach((user) => {
//     user.Loans.forEach((loan) => {
//       let completed = [];
//       let currentTime = Date.now();
//       let lastTimestamp = currentTime;
//       loan.LoanTasks.forEach((task) => {
//         completed = [...completed, task.Completed];
//         lastTimestamp = Math.min(task.Timestamp);
//       });
//       if (
//         completed.every((e) => e === true) &&
//         currentTime - lastTimestamp < 365 * 24 * 60 * 60 * 1000
//       ) {
//         const completedMonth = new Date(lastTimestamp).getMonth();
//         completedLoanCountByMonth[completedMonth]++;
//       }
//     });
//   });
//   return completedLoanCountByMonth;
// };

// /**
//  * NOT IN USE
//  *
//  * Helper function to find the number of loans started for the past 365 days
//  *
//  * @param {*} data user data
//  * @returns array of loans started for the past 365 days (January always in position 0)
//  */
// const findStartedLoanCountByMonth = (data) => {
//   const startedLoanCountByMonth = new Array(12).fill(0);
//   const currentTime = Date.now();
//   data.forEach((user) => {
//     user.Loans.forEach((loan) => {
//       if (currentTime - loan.Timestamp < 365 * 24 * 60 * 60 * 1000) {
//         const startedMonth = new Date(loan.Timestamp).getMonth();
//         startedLoanCountByMonth[startedMonth]++;
//       }
//     });
//   });
//   return startedLoanCountByMonth;
// };

/**
 * Helper function to Rotate months array or chart data array according to the current month
 *
 * @param {*} arr months array or chart data array
 * @returns rotated array
 */
const rotateArray = (arr) => {
  let newArray = [];
  const currentMonth = new Date().getMonth();
  for (let i = currentMonth + 1; i <= currentMonth + 12; i++) {
    newArray.push(arr[i % 12]);
  }
  return newArray;
};

/**
 * Dashboard component
 */
const Dashboard = ({ users }) => {
  const [userData, setUserData] = useState(users);
  const [newUploads, setNewUploads] = useState([]);
  const [completedLoans, setCompletedLoans] = useState([]);
  const [incompletedLoans, setIncompletedLoans] = useState([]);
  const [loanStartedData, setLoanStartedData] = useState([]);
  const [loanCompletedData, setLoanCompletedData] = useState([]);

  useEffect(() => {
    // used for recording loan counts for chart data
    const completedLoanCountByMonth = new Array(12).fill(0); 
    const startedLoanCountByMonth = new Array(12).fill(0); 

    // calulate timestamp for first day of next month last year
    const date = new Date();
    const cutOffDateTimestamp = new Date(date.getFullYear() - 1, date.getMonth() + 1, 1).getTime();

    if(userData && userData.length > 0){
      userData.forEach((user) => {
        user.Loans.forEach((loan) => {
          let completed = []; // used for finding if all tasks in a loan are completed
          let lastTimestamp = Date.now(); //used for finding timestamp for last uploaded item

          // data for loan started chart
          if (loan.Timestamp > cutOffDateTimestamp) {
            const startedMonth = new Date(loan.Timestamp).getMonth();
            startedLoanCountByMonth[startedMonth]++;
          }

          loan.LoanTasks.forEach((task) => {
            // get task completion status and record latest time stamp
            completed.push(task.Completed);
            lastTimestamp = Math.min(task.Timestamp);

            // add new uploaded loan task files to newUploads state array
            if (task.BoxFileID) {
              setNewUploads((newUploads) => [
                ...newUploads,
                {
                  name: user.Name,
                  task: task.Name,
                  folderID: loan.BoxFolderID,
                  fileID: task.BoxFileID,
                  type: loan.LoanType,
                  timestamp: task.Timestamp,
                },
              ]);
            }
          });

          // check if all task are completed
          if (completed.every((e) => e === true)) {
            // add the loan to competed loans
            setCompletedLoans((completedLoans) => [
              ...completedLoans,
              {
                userName: user.Name,
                loanName: loan.LoanType,
                loanFolderID: loan.BoxFolderID,
                timestamp: lastTimestamp,
              },
            ]);
            // add to completed loan chart data if completed within the last 12 months
            if (lastTimestamp > cutOffDateTimestamp) {
              const completedMonth = new Date(lastTimestamp).getMonth();
              completedLoanCountByMonth[completedMonth]++;
            }
          }else{
            setIncompletedLoans((incompletedLoans) => [
              ...incompletedLoans,
              {
                userName: user.Name,
                loanName: loan.LoanType,
                timestamp: loan.Timestamp,
              },
            ]);
          }
        });

        // add new uploaded profile task files to newUploads state array
        user.Tasks.forEach((task) => {
          if (task.BoxFileID) {
            setNewUploads((newUploads) => [
              ...newUploads,
              {
                name: user.Name,
                task: task.Name,
                folderID: user.PersonalFolder,
                fileID: task.BoxFileID,
                type: "profile",
                timestamp: task.Timestamp,
              },
            ]);
          }
        });
      });
    }
    setNewUploads((newUploads) =>
      [...newUploads].sort((first, second) => second.timestamp - first.timestam)
    );
    setCompletedLoans((completedLoans) =>
      [...completedLoans].sort((first, second) => second.timestamp - first.timestamp)
    );
    setLoanCompletedData(rotateArray(completedLoanCountByMonth));
    setLoanStartedData(rotateArray(startedLoanCountByMonth));
  }, [userData]);

  // chart variables
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const chartLabels = rotateArray(months);
  const chartMaxY = Math.max(Math.max.apply(Math, loanStartedData) + 1,
    Math.max.apply(Math, loanCompletedData) + 1, 6);

  return (
    <>
      {/* Charts */}
      <h2 className="text-align-center margin-top-10 margin-bottom-10">
        Metrics
      </h2>
      <div className="flex-row justify-content-space-between content-card-no-border text-align-center">
        <ChartLoanStarted 
          labels={chartLabels}
          chartData={loanStartedData} 
          maxY={chartMaxY}
        />
        <ChartLoanCompleted 
          labels={chartLabels}
          chartData={loanCompletedData} 
          maxY={chartMaxY}
        />
      </div>

      {/* Recent items */}
      <h2 className="text-align-center margin-top-10 margin-bottom-10">
        Recent Items
      </h2>
      <NewUploads data={newUploads} />
      <CompletedLoans data={completedLoans} />
      <IncompletedLoans data={incompletedLoans} />
    </>
  );
};

export default Dashboard;
