import React, { useEffect, useState } from "react";

import { Route, useRouteMatch } from "react-router-dom";

import { GET_USERS, GET_LOAN_CONFIG } from "../../utils/api";

import Dashboard from "../MainPages/Dashboard/Dashboard";
import FilesPage from "../MainPages/Files/Files";
import ManageUsersPage from "../MainPages/ManageUsers";
import Header from "../../components/Header";
import ManageLoanPage from "../MainPages/ManageLoan";
import ManageProfileTasksPage from "../MainPages/ManageProfileTasks";
import Footer from "../../components/Footer";

const MainPage = () => {
  const match = useRouteMatch();

  const [users, setUsers] = useState([]);
  const [loanConfig, setLoanConfig] = useState(null); // { LoanTypes: {}, Tasks: [] }

  async function updateLoanConfig() {
    try {
      const { Item } = await GET_LOAN_CONFIG();
      setLoanConfig(Item);
    } catch (err) {
      console.error(err);
    }
  }

  async function updateUsers() {
    try {
      const response = await GET_USERS();
      setUsers(response);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    updateLoanConfig();
    updateUsers();
  }, []);

  return (
    <>
      <div className="flex-column page">
        {/* Header */}
        <Header />

        {/* Body */}
        <>
          <Route
            exact
            path={match.path}
            component={() => <Dashboard users={users} />}
          />
          <Route
            exact
            path={`${match.path}/files`}
            component={() => <FilesPage />}
          />
          <Route
            exact
            path={`${match.path}/customers`}
            component={() => (
              <ManageUsersPage
                loanConfig={loanConfig}
                users={users}
                updateUsers={updateUsers}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/loans`}
            component={() => (
              <ManageLoanPage
                loanConfig={loanConfig}
                updateLoanConfig={updateLoanConfig}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/profiletasks`}
            component={() => (
              <ManageProfileTasksPage
                loanConfig={loanConfig}
                updateLoanConfig={updateLoanConfig}
              />
            )}
          />
        </>

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default MainPage;
