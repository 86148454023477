import React from 'react';

const Footer = () => (
  <footer className="flex-row background-black" style={{ width: '100%' }}>
    <div className="margin-auto content-max-width">
      <div className="text-align-left color-white margin-left-20 padding-top-20 padding-bottom-20">
        <p className="">Reva Solutions &copy; 2020</p>
        <p>Careers | Legal | Privacy & Security</p>
      </div>
    </div>
  </footer>
);

export default Footer;
