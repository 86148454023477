import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";

import { GET_DOCUMENT_AI_RESULT } from "../../../utils/api";

function extractText(textAnchor, documentResult) {
  if (documentResult) {
    const { text } = documentResult;
    // First shard in document doesn't have startIndex property
    const startIndex = textAnchor.textSegments[0].startIndex || 0;
    const { endIndex } = textAnchor.textSegments[0];

    return text.substring(startIndex, endIndex);
  }
}

const LoanDocumentInfoModal = ({ modalOpen, fileID }) => {
  const [documentResult, setDocumentResult] = useState(null);
  const [getResultFailed, setGetResultFailed] = useState(false);

  const getDocumentAIResult = async (boxFileID) => {
    try {
      if (!boxFileID) return;
      const response = await GET_DOCUMENT_AI_RESULT(boxFileID);
      setDocumentResult(response);
    } catch (err) {
      setGetResultFailed(true);
      console.log(err);
    }
  };

  useEffect(() => {
    getDocumentAIResult(fileID);
  }, []);

  return (
    <div className="modal detailModal flex-column">
      <div className="padding-top-30 padding-left-30 padding-right-30 content-card-full-width content-modal-height flex-column">
        {/* Title */}
        <div className="margin-bottom-30 flex-row justify-content-space-between">
          <h1>Extraction Results</h1>
          <button
            className="cursor-pointer flex-row align-items-center orange"
            onClick={() => modalOpen(false)}
            type="button"
            style={{ borderRadius: 100 }}
          >
            <CloseIcon />
          </button>
        </div>

        {/* Content */}
        {documentResult && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell align="right">Value</TableCell>
                  <TableCell align="right">Type</TableCell>
                  <TableCell align="right">Confidence</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documentResult.entities.map((entity, index) => {
                  const { textAnchor, confidence, mentionText, type } = entity;
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {extractText(textAnchor, documentResult)}
                      </TableCell>
                      <TableCell align="right">{mentionText}</TableCell>
                      <TableCell align="right">{type}</TableCell>
                      <TableCell align="right">
                        {Math.round(confidence * 100)}%
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Loading */}
        {!documentResult && !getResultFailed && (
          <>
            <div className="text-align-center">
              <CircularProgress size={60} />
            </div>
          </>
        )}

        {/* Get result failed */}
        {getResultFailed && (
          <>
            <h2>The file is still being processed...</h2>
            <br />
            <h3>Please try again later.</h3>
          </>
        )}
      </div>
    </div>
  );
};

export default LoanDocumentInfoModal;
