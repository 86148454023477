import React from "react";

import { Line } from "react-chartjs-2";

const ChartLoanStarted = ({ labels, chartData, maxY }) => {
  const loanStartedChartData = {
    labels: labels,
    datasets: [
      {
        label: "Loan started",
        data: chartData,
        fill: false,
        backgroundColor: "#223F7F",
        borderColor: "#7599E6",
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: maxY,
      },
    },
  };

  return (
    <div className="content-card-half-width">
      <Line data={loanStartedChartData} options={chartOptions} />
    </div>
  );
};

export default ChartLoanStarted;
