import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { CREATE_USER } from "../../../utils/api";

// DOM Id's
const USER_FIRST_NAME_ID = "firstNameId";
const USER_LAST_NAME_ID = "lastNameId";
const USER_EMAIL_ID = "emailId";
const USER_LOAN_NUMBER = "createLoanNumberId";
const USER_LOAN_TYPE = "createLoanTypeId";

const CreateUserModal = ({
  modalOpen,
  setModalOpen,
  updateUsers,
  loanConfig,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [loanNumber, setLoanNumber] = useState("");
  const [loanType, setLoanType] = useState("");

  const [profileTasks, setProfileTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState("");

  const [creating, setCreating] = useState(false);
  const [completed, setCompleted] = useState(false);

  /**
   * Create user based on parameters sent.
   */
  const createUser = async (e) => {
    e.preventDefault();
    setCreating(true);
    const payload = {
      name: `${firstName} ${lastName}`,
      email,
      loanNumber,
      loanType,
      profileTasks,
    };
    try {
      await CREATE_USER(payload);
      setCompleted(true);
      setCreating(false);
    } catch (err) {
      setCompleted(true);
      alert(err);
    }
  };

  /**
   * Close modal and reset filled parameters
   */
  const closeModal = async () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setLoanNumber("");
    if (loanConfig) {
      setLoanType(Object.keys(loanConfig.LoanTypes)[0]);
    }
    setModalOpen(false);
    setCreating(false);
    setCompleted(false);
    setProfileTasks([]);
    if (completed) {
      await updateUsers();
    }
  };

  /**
   * Add Task to State
   */
  const addTask = () => {
    if (!selectedTask) {
      return;
    }
    const newProfileTasks = [...profileTasks];
    newProfileTasks.push(selectedTask);
    setProfileTasks(newProfileTasks);
  };

  // Update defaults when loan config gets retrieved.
  useEffect(() => {
    if (loanConfig) {
      setLoanType(Object.keys(loanConfig.LoanTypes)[0]);
      setSelectedTask(Object.keys(loanConfig.Tasks)[0]);
    }
  }, [loanConfig]);

  const availableTasks = useMemo(() => {
    if (loanConfig && loanConfig.Tasks) {
      const allTasks = { ...loanConfig.Tasks };
      profileTasks.forEach((task) => {
        if (Object.keys(allTasks).includes(task)) {
          delete allTasks[task];
        }
      });
      setSelectedTask(Object.keys(allTasks)[0]);
      return allTasks;
    }
    return null;
  }, [profileTasks]);

  return (
    <div
      className="modal"
      style={{ display: `${modalOpen ? "flex" : "none"}` }}
    >
      <div className="modal-content flex-column">
        <div className="margin-bottom-30 flex-row justify-content-space-between">
          <h1>Create Customer Form</h1>
          <button
            className="cursor-pointer flex-row align-items-center orange"
            onClick={closeModal}
            type="button"
            style={{ borderRadius: 100 }}
          >
            <CloseIcon />
          </button>
        </div>

        {/* Form UI */}
        {!creating && !completed && (
          <form className="flex-row" onSubmit={createUser}>
            {/* User Information */}
            <div className="flex-column margin-right-30">
              <h2 className="margin-bottom-20">User Information</h2>
              <label
                className="margin-bottom-20 flex-row"
                htmlFor={USER_FIRST_NAME_ID}
              >
                <p style={{ width: 125 }}>First Name:</p>
                <input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  id={USER_FIRST_NAME_ID}
                />
              </label>
              <label
                className="margin-bottom-20 flex-row"
                htmlFor={USER_LAST_NAME_ID}
              >
                <p style={{ width: 125 }}>Last Name:</p>
                <input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </label>
              <label
                className="margin-bottom-20 flex-row"
                htmlFor={USER_EMAIL_ID}
              >
                <p style={{ width: 125 }}>Email:</p>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id={USER_EMAIL_ID}
                />
              </label>
              <input
                type="submit"
                value="Create User"
                className="margin-top-20 padding-left-20 padding-right-20 padding-top-10 padding-bottom-10 cursor-pointer button background-blue color-white"
                style={{ width: 125 }}
              />
            </div>
            {/* Loan and Profile Tasks */}
            <div className="flex-column">
              <h2 className="margin-bottom-20">Loan Information</h2>
              <label
                className="margin-bottom-20 flex-row"
                htmlFor={USER_LOAN_NUMBER}
              >
                <p style={{ width: 125 }}>Loan Number:</p>
                <input
                  value={loanNumber}
                  onChange={(e) => setLoanNumber(e.target.value)}
                  id={USER_LOAN_NUMBER}
                />
              </label>
              <label
                className="margin-bottom-20 flex-row"
                htmlFor={USER_LOAN_TYPE}
              >
                <p style={{ width: 125 }}>Loan Type:</p>
                <select
                  value={loanType}
                  onChange={(e) => setLoanType(e.target.value)}
                  id={USER_LOAN_TYPE}
                >
                  {loanConfig &&
                    Object.keys(loanConfig.LoanTypes).map((lType) => (
                      <option value={lType} key={lType}>
                        {lType}
                      </option>
                    ))}
                </select>
              </label>

              <h2>Tasks</h2>
              {profileTasks.map((task) => (
                <p key={task}>{task}</p>
              ))}
              <div>
                <select
                  value={selectedTask}
                  onChange={(e) => setSelectedTask(e.target.value)}
                >
                  {availableTasks &&
                    Object.keys(availableTasks).map((task) => (
                      <option value={task} key={task}>
                        {task}
                      </option>
                    ))}
                </select>
                <button
                  type="button"
                  className="margin-left-10 background-orange padding-5 color-white cursor-pointer"
                  onClick={addTask}
                >
                  Add
                </button>
              </div>
            </div>
          </form>
        )}

        {/* Loading UI */}
        {creating && !completed && (
          <div className="text-align-center">
            <CircularProgress size={60} />
          </div>
        )}
        {/* Completed UI */}
        {!creating && completed && (
          <div className="text-align-center">
            <h3 className="margin-bottom-20">Customer created successfully!</h3>
            <button
              type="button"
              className="padding-10 cursor-pointer"
              onClick={closeModal}
            >
              Close Modal
            </button>
          </div>
        )}
        {/* Error UI */}
        {creating && completed && (
          <div className="text-align-center">
            <h3 className="margin-bottom-20">
              Error in creating. Please try again.
            </h3>
            <button
              type="button"
              className="padding-10 cursor-pointer"
              onClick={closeModal}
            >
              Close Modal
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

CreateUserModal.defaultProps = {
  selectedUser: null,
  loanConfig: null,
};

CreateUserModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  selectedUser: PropTypes.shape({
    boxAppUserID: PropTypes.string,
    name: PropTypes.string,
    cognitoID: PropTypes.string,
  }),
  setModalOpen: PropTypes.func.isRequired,
  updateUsers: PropTypes.func.isRequired,
  loanConfig: PropTypes.shape({
    LoanTypes: PropTypes.shape({}),
    Tasks: PropTypes.shape({}),
  }),
};

export default CreateUserModal;
