import React from "react";
import HelpIcon from "@material-ui/icons/Help";
import { NavLink, useRouteMatch } from "react-router-dom";

import logo from "../../assets/logo-whiteorange.png";

const Header = () => {
  const match = useRouteMatch();
  return (
    <header className="flex-row padding-left-30 padding-right-30 background-purple margin-bottom-20">
      <div
        className="flex-row align-items-center margin-auto justify-content-space-between content-max-width"
        style={{ height: "inherit" }}
      >
        <img
          src={logo}
          className="margin-top-10 margin-top-auto margin-bottom-auto margin-right-40"
          style={{ height: "calc(100% - 40px)" }}
          alt="Reva Solutions Logo"
        />
        <div className="flex-row align-items-center">
          <h5 className="color-white margin-right-30">
            <NavLink
              className="color-white padding-left-10 padding-right-10 blue-hover"
              exact
              to={match.url}
              activeClassName="padding-bottom-10 color-blue border-bottom-blue"
            >
              DASHBOARD
            </NavLink>
          </h5>
          <h5 className="color-white margin-right-30">
            <NavLink
              className="color-white padding-left-10 padding-right-10 blue-hover"
              to={`${match.url}/files`}
              activeClassName="padding-bottom-10 color-blue border-bottom-blue"
            >
              FILES
            </NavLink>
          </h5>
          <h5 className="margin-right-30">
            <NavLink
              className="color-white padding-left-10 padding-right-10 blue-hover"
              to={`${match.url}/customers`}
              activeClassName="padding-bottom-10 color-blue border-bottom-blue"
            >
              CUSTOMERS
            </NavLink>
          </h5>
          <h5 className="color-white margin-right-30">
            <NavLink
              className="color-white padding-left-10 padding-right-10 blue-hover"
              to={`${match.url}/loans`}
              activeClassName="padding-bottom-10 color-blue border-bottom-blue"
            >
              LOAN SETUP
            </NavLink>
          </h5>
          <h5 className="color-white margin-right-30">
            <NavLink
              className="color-white padding-left-10 padding-right-10 blue-hover"
              to={`${match.url}/profiletasks`}
              activeClassName="padding-bottom-10 color-blue border-bottom-blue"
            >
              PROFILE TASKS SETUP
            </NavLink>
          </h5>
          <HelpIcon className="color-white" />
        </div>
      </div>
    </header>
  );
};

export default Header;
