import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import { ADD_LOAN } from '../../../utils/api';

const AddLoanModal = ({
  modalOpen, selectedUser, setModalOpen, updateUsers, loanConfig,
}) => {
  const [loanNumber, setLoanNumber] = useState('');
  const [loanType, setLoanType] = useState('');

  const [creating, setCreating] = useState(false);
  const [completed, setCompleted] = useState(false);

  /**
   * Create loan specified by the loan number to the user selected.
   */
  const createLoan = async (e) => {
    e.preventDefault();
    setCreating(true);
    const payload = {
      boxAppUserID: selectedUser.boxAppUserID,
      cognitoID: selectedUser.cognitoID,
      loanNumber,
      loanType,
    };
    try {
      const result = await ADD_LOAN(payload);
      console.log(result);
      setCreating(false);
      setCompleted(true);
    } catch (err) {
      setCompleted(true);
      alert(err);
    }
  };

  /**
   * Close modal and reset filled parameters
   */
  const closeModal = async () => {
    // Prevents user from leaving modal when request is sent.
    if (!creating) {
      setCreating(false);
      setCompleted(false);
      setModalOpen(false);
      setLoanNumber('');
    }
    if (completed) {
      await updateUsers();
    }
  };

  // Update defaults when loan config gets retrieved.
  useEffect(() => {
    if (loanConfig) {
      setLoanType(Object.keys(loanConfig.LoanTypes)[0]);
    }
  }, [loanConfig]);

  return (
    <div className="modal" style={{ display: `${modalOpen ? 'flex' : 'none'}` }}>
      <div className="modal-content flex-column">
        <div className="margin-bottom-30 flex-row justify-content-space-between">
          {selectedUser && (
            <h1>{`Add Loan for ${selectedUser.name}`}</h1>
          )}
          <button className="cursor-pointer flex-row align-items-center orange" onClick={closeModal} type="button" style={{ borderRadius: 100 }}>
            <CloseIcon />
          </button>
        </div>
        {/* Form UI */}
        {
          !creating && !completed && (
            <form className="flex-column" onSubmit={createLoan}>
              <h2 className="margin-bottom-20">Loan Information</h2>
              <label className="margin-bottom-20 flex-row" htmlFor="addLoanNumber">
                <p style={{ width: 125 }}>Loan Number:</p>
                <input value={loanNumber} onChange={(e) => setLoanNumber(e.target.value)} id="addLoanNumber" />
              </label>
              <label className="margin-bottom-20 flex-row" htmlFor="addLoanType">
                <p style={{ width: 125 }}>Loan Type:</p>
                <select value={loanType} onChange={(e) => setLoanType(e.target.value)} id="addLoanType">
                  {loanConfig && Object.keys(loanConfig.LoanTypes).map((lType) => (
                    <option value={lType} key={lType}>{lType}</option>
                  ))}
                </select>
              </label>
              <input
                type="submit"
                value="Add Loan"
                className="padding-left-20 padding-right-20 padding-top-10 padding-bottom-10 cursor-pointer button background-blue color-white"
                style={{ width: 125 }}
              />
            </form>
          )
        }
        {/* Loading UI */}
        {
          creating && !completed && (
            <div className="text-align-center">
              <CircularProgress size={60} />
            </div>
          )
        }
        {/* Completed UI */}
        {
          !creating && completed && (
            <div className="text-align-center">
              <h3 className="margin-bottom-20">
                Loan created successfully!
              </h3>
              <button type="button" className="padding-10 cursor-pointer" onClick={closeModal}>Close Modal</button>
            </div>
          )
        }
        {/* Error UI */}
        {
          creating && completed && (
            <div className="text-align-center">
              <h3 className="margin-bottom-20">
                Error in creating a loan. Please try again.
              </h3>
              <button type="button" className="padding-10 cursor-pointer" onClick={closeModal}>Close Modal</button>
            </div>
          )
        }
      </div>
    </div>
  );
};

AddLoanModal.defaultProps = {
  selectedUser: null,
  loanConfig: null,
};

AddLoanModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  selectedUser: PropTypes.shape({
    boxAppUserID: PropTypes.string,
    name: PropTypes.string,
    cognitoID: PropTypes.string,
  }),
  setModalOpen: PropTypes.func.isRequired,
  updateUsers: PropTypes.func.isRequired,
  loanConfig: PropTypes.shape({
    LoanTypes: PropTypes.shape({}),
  }),
};

export default AddLoanModal;
