import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect as Redirecting,
} from 'react-router-dom';

import IndexPage from './pages/Index';
import RedirectPage from './pages/Redirect';
import MainPage from './pages/Main';

import { VALIDATE_TOKEN } from './utils/api';

const authenticate = async () => {
  try {
    const response = await VALIDATE_TOKEN();
    return response;
  } catch (err) {
    return false;
  }
};

/**
 * Private Routes that require valid credentials must pass authentication
 */
function PrivateRoute({ component: Page, ...rest }) {
  const [auth, setAuth] = useState(true);

  useEffect(() => {
    async function checkUser() {
      const res = await authenticate();
      setAuth(res);
    }
    checkUser();
  }, []);

  return (
    <Route
      {...rest}
      render={props =>
        auth ? <Page {...rest} /> : (
          <Redirecting to="/" />
        )
      }
    />
  );
}

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={IndexPage} />
        <Route exact path="/redirect" component={RedirectPage} />
        <PrivateRoute path="/main" component={MainPage} />
      </Switch>
    </Router>
  );
}
