import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmEditModal from "./ConfirmEditModal";
import { EDIT_LOAN } from "../../../utils/api";

// DOM Id's
const LOAN_NAME = "loanNameIdToEdit";
const TASK_NAME = "loanTaskHeaderToEdit";

const EditLoanModal = ({ modalOpen, setModalOpen, updateLoanConfig, selectedLoan, selectedLoanTasks }) => {
  const [loanName, setLoanName] = useState(selectedLoan);
  const [loanTasks, setLoanTasks] = useState(selectedLoanTasks);

  const [editConfirmModalOpen, setEditConfirmModalOpen] = useState(false);

  const [editing, setEditing] = useState(false);
  const [completed, setCompleted] = useState(false);

  // Close Modal and reset state.
  const closeModal = async () => {
    setLoanName(selectedLoan);
    setLoanTasks(selectedLoanTasks);
    setModalOpen(false);
    if (completed) {
      await updateLoanConfig();
    }
  };

  /**
   * Add a task object to the loanTasks state
   * @param {*} e event
   */
  const addTask = (e) => {
    e.preventDefault();
    const task = {
      Name: "",
      UserAction: true,
      ToExtract: true,
    };
    const newTasks = [...loanTasks];
    newTasks.push(task);
    setLoanTasks(newTasks);
  };

  /**
   * Change a property in the task object
   * @param {*} event event
   * @param {*} index number
   * @param {*} param string, Name or UserAction
   */
  const changeLoanParams = (event, index, param) => {
    event.preventDefault();
    const newTasks = [...loanTasks];
    const task = newTasks[index];
    task[param] = event.target.value;
    setLoanTasks(newTasks);
  };

  /**
   * Toggle UserAction checkbox for Task
   * @param {*} event event
   * @param {*} index number
   */
  const toggleUserAction = (event, index) => {
    const newTasks = [...loanTasks];
    const task = newTasks[index];
    task.UserAction = event.target.checked;
    if (!event.target.checked) {
      task.ToExtract = false;
    }
    setLoanTasks(newTasks);
  };

  /**
   * Toggle ToExtract checkbox for Task
   * @param {*} event event
   * @param {*} index number
   */
  const toggleExtractDocument = (event, index) => {
    const newTasks = [...loanTasks];
    const task = newTasks[index];
    task.ToExtract = event.target.checked;
    setLoanTasks(newTasks);
  };

  const handleMouseEnter = (index) => {
    const newLoanTasks = [...loanTasks];
    newLoanTasks[index].IsHovered = true;
    setLoanTasks(newLoanTasks);
  };

  const handleMouseLeave = (index) => {
    const newLoanTasks = [...loanTasks];
    newLoanTasks[index].IsHovered = false;
    setLoanTasks(newLoanTasks);
  };

  const removeTask = (index) => {
    setLoanTasks(
      loanTasks
        .slice(0, index)
        .concat(loanTasks.slice(index + 1, loanTasks.length))
    );
  };

  const editLoan = async () => {
    if (loanTasks.length === 0) {
      alert("Error: Add tasks to the loan before creating.");
      return;
    }
    if (loanName.length <= 1) {
      alert("Error: Loan Name does not meet requirements.");
      return;
    }
    loanTasks.map((task) => delete task.IsHovered);
    const payload = {
      loanType: selectedLoan,
      newName: loanName,
      tasks: loanTasks,
    };
    setEditing(true);
    try {
      await EDIT_LOAN(payload);
      setEditing(false);
      setCompleted(true);
    } catch (err) {
      setCompleted(true);
      alert(err);
    }
  };

  return (
    <div
      className="modal detailModal"
      style={{ display: `${modalOpen ? "flex" : "none"}` }}
    >
      <div className="modal-content flex-column">
        {/* Header */}
        <div className="margin-bottom-30 flex-row justify-content-space-between">
          <h1>Edit Loan Type</h1>
          <button
            className="cursor-pointer flex-row align-items-center orange"
            onClick={closeModal}
            type="button"
            style={{ borderRadius: 100 }}
          >
            <CloseIcon />
          </button>
        </div>

        {/* Form UI */}
        {!editing && !completed && (
          <div>
            <form className="flex-column">
              <label className="margin-bottom-20 flex-row" htmlFor={LOAN_NAME}>
                <p style={{ width: 125 }}>Loan Name:</p>
                <input
                  value={loanName}
                  onChange={(e) => setLoanName(e.target.value)}
                  id={LOAN_NAME}
                />
              </label>
              {/* Tasks */}
              <label htmlFor={TASK_NAME}>
                <div className="flex-row align-items-center margin-bottom-20">
                  <p style={{ width: 125 }}>Tasks:</p>
                  <button
                    onClick={addTask}
                    type="button"
                    className="padding-top-5 padding-bottom-5 cursor-pointer"
                    style={{ width: 125 }}
                  >
                    Add Task
                  </button>
                </div>

                {Object.keys(loanTasks).length === 0 && (
                  <p className="margin-bottom-20">
                    No tasks added. Please add a task to the loan.
                  </p>
                )}
              </label>
              <div>
                {loanTasks.map((task, index) => {
                  const { Name, UserAction, ToExtract } = task;
                  return (
                    <div
                      key={`${index}`}
                      className="flex-row"
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                    >
                      <label
                        className="margin-bottom-20 flex-row margin-right-20"
                        htmlFor={`${Name} - ${index} - name`}
                      >
                        <p style={{ width: 125 }}>Task Name:</p>
                        <input
                          value={Name}
                          onChange={(e) => changeLoanParams(e, index, "Name")}
                          id={`${Name} - ${index} - name`}
                        />
                      </label>
                      <label
                        className="margin-bottom-20 flex-row margin-right-40"
                        htmlFor={`${Name} - ${index} - useraction`}
                      >
                        <p style={{ width: 100 }}>User Action:</p>
                        <input
                          type="checkbox"
                          value={UserAction}
                          onChange={(e) => toggleUserAction(e, index)}
                          id={`${UserAction} - ${index} - useraction - edit`}
                          checked={UserAction}
                        />
                      </label>
                      <label
                        className="margin-bottom-20 flex-row"
                        htmlFor={`${Name} - ${index} - ToExtract - edit`}
                      >
                        <p style={{ width: 100 }}>Extract Doc:</p>
                        <input
                          type="checkbox"
                          value={ToExtract}
                          onChange={(e) => toggleExtractDocument(e, index)}
                          id={`${ToExtract} - ${index} - ToExtract - edit`}
                          checked={ToExtract}
                          disabled={!UserAction}
                        />
                      </label>
                      {loanTasks[index].IsHovered && (
                        <label
                          className="margin-bottom-20 flex-row"
                          htmlFor={`${Name} - ${index} - edit - delete`}
                        >
                          <button
                            onClick={() => removeTask(index)}
                            style={{
                              background: "none",
                              border: "none",
                              color: "red",
                              marginLeft: "20px",
                            }}
                          >
                            &#10006;
                          </button>
                        </label>
                      )}
                    </div>
                  );
                })}
              </div>
              <input
                className="padding-left-20 padding-right-20 padding-top-10 padding-bottom-10 cursor-pointer button background-blue color-white text-align-center"
                style={{ width: 125 }}
                value="Edit"
                onClick={() => setEditConfirmModalOpen(true)}
                readOnly
              />
            </form>
          </div>
        )}

        {/* Confirm */}
        {editConfirmModalOpen && (
          <ConfirmEditModal
            processing={editing}
            completed={completed}
            closeModal={closeModal}
            operation={editLoan}
            operationName={"Edit"}
            contentMessage={[
              "About to edit ",
              <strong>{selectedLoan}</strong>,
              ". Loans that are already assigned will ",
              <strong>not</strong>,
              " be changed.",
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default EditLoanModal;
