import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import { CREATE_PROFILE_TASK } from '../../../utils/api';

// DOM Id's
const TASK_NAME = 'taskNameId';
const TASK_TO_EXTRACT = 'taskToExtractId';

const CreateTaskModal = ({ modalOpen, setModalOpen, updateLoanConfig }) => {
  const [taskName, setTaskName] = useState('');
  const [toExtract, setToExtract] = useState(false);

  const [creating, setCreating] = useState(false);
  const [completed, setCompleted] = useState(false);

  // Close Modal and reset state.
  const closeModal = async () => {
    setModalOpen(false);
    setTaskName('');
    setToExtract(false);
    if (completed) {
      await updateLoanConfig();
    }
  };

  const toggleToExtract = (event) => {
    setToExtract(event.target.checked);
  };

  /**
   * Create Task.
   */
  const createTask = async () => {
    const payload = { Name: taskName, ToExtract: toExtract };
    setCreating(true);
    try {
      await CREATE_PROFILE_TASK(payload);
      setCreating(false);
      setCompleted(true);
    } catch (err) {
      setCompleted(true);
      alert(err);
    }
  };

  return (
    <div className="modal detailModal" style={{ display: `${modalOpen ? 'flex' : 'none'}` }}>
      <div className="modal-content flex-column">
        {/* Header */}
        <div className="margin-bottom-30 flex-row justify-content-space-between">
          <h1>Create New Profile Task</h1>
          <button className="cursor-pointer flex-row align-items-center orange" onClick={closeModal} type="button" style={{ borderRadius: 100 }}>
            <CloseIcon />
          </button>
        </div>

        {/* Form UI */}
        {
          !creating && !completed && (
            <div>
              <form className="flex-column">
                <label className="margin-bottom-20 flex-row" htmlFor={TASK_NAME}>
                  <p style={{ width: 150 }}>Task Name:</p>
                  <input
                    value={taskName}
                    onChange={(e) => setTaskName(e.target.value)}
                    id={TASK_NAME}
                    placeholder="Upload Something?"
                  />
                </label>
                <label className="margin-bottom-20 flex-row margin-right-40" htmlFor={TASK_TO_EXTRACT}>
                  <p style={{ width: 150 }}>Extract Document:</p>
                  <input
                    type="checkbox"
                    value={toExtract}
                    onChange={(e) => toggleToExtract(e)}
                    id={TASK_TO_EXTRACT}
                    checked={toExtract}
                  />
                </label>
                <input
                  className="padding-left-20 padding-right-20 padding-top-10 padding-bottom-10 cursor-pointer button background-blue color-white text-align-center"
                  style={{ width: 125 }}
                  value="Create Task"
                  onClick={createTask}
                  readOnly
                />
              </form>
            </div>
          )
        }

        {/* Loading UI */}
        {
          creating && !completed && (
            <div className="text-align-center">
              <CircularProgress size={60} />
            </div>
          )
        }
        {/* Completed UI */}
        {
          !creating && completed && (
            <div className="text-align-center">
              <h3 className="margin-bottom-20">
                Task created successfully!
              </h3>
              <button type="button" className="padding-10 cursor-pointer" onClick={closeModal}>Close Modal</button>
            </div>
          )
        }
        {/* Error UI */}
        {
          creating && completed && (
            <div className="text-align-center">
              <h3 className="margin-bottom-20">
                Error in creating a task. Please try again.
              </h3>
              <button type="button" className="padding-10 cursor-pointer" onClick={closeModal}>Close Modal</button>
            </div>
          )
        }
      </div>
    </div>
  );
};

CreateTaskModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  updateLoanConfig: PropTypes.func.isRequired,
};

export default CreateTaskModal;
