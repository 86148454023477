import React, { useState } from "react";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CreateTaskModal from "./CreateTaskModal";
import { DELETE_PROFILE_TASK } from "../../../utils/api";
import ConfirmModal from "./ConfirmModal";
import EditTaskModal from "./EditTaskModal";

const ManageProfileTasksPage = ({ loanConfig, updateLoanConfig }) => {
  const [createProfileTaskModalOpen, setCreateProfileTaskModalOpen] = useState(
    false
  );
  const [onDelete, setOnDelete] = useState(false);
  const [onToggleEdit, setOnToggleEdit] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const [processing, setProcessing] = useState(false);
  const [completed, setCompleted] = useState(false);

  const selectTask = (task) => {
    if (task === selectedTask) {
      setSelectedTask(null);
      return;
    }
    setSelectedTask(task);
  };

  // Close Modal and reset state.
  const closeModal = async () => {
    setOnDelete(false);
    setOnToggleEdit(false);
    if (completed) {
      await updateLoanConfig();
    }
  };

  const deleteProfileTask = async () => {
    const payload = { task: selectedTask };
    setProcessing(true);
    try {
      await DELETE_PROFILE_TASK(payload);
      setProcessing(false);
      setCompleted(true);
    } catch (err) {
      setCompleted(true);
      alert(err);
    }
  };

  // Render Loan Types in LoanConfig
  const listTasks = () => (
    <TableContainer>
      <Table aria-label="Loan Types Table">
        <TableHead>
          <TableRow>
            <TableCell>
              <h4><b>Profile Task Name</b></h4>
            </TableCell>
          </TableRow>
        </TableHead>
        {loanConfig && loanConfig.Tasks && (
          <TableBody>
            {Object.keys(loanConfig.Tasks).map((loanType) => {
              const selectedRow = loanType === selectedTask;
              return (
                <TableRow
                  key={loanType}
                  onClick={() => selectTask(loanType)}
                  className={`${
                    selectedRow && "background-orange"
                  } cursor-pointer`}
                >
                  <TableCell>
                    <span className={`${selectedRow && "color-white"}`}>
                      {loanType}
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );

  const listTaskDetails = () => {
    if (selectedTask) {
      const task = loanConfig.Tasks[selectedTask];
      const { ToExtract } = task;
      return (
        <div className="margin-bottom-10">
          <p>{`Name: ${selectedTask}`}</p>
          <div className="flex-row">
            <p>Extract Document: </p>
            <input type="checkbox" checked={ToExtract} disabled />
          </div>
          <button
            className="margin-top-20 margin-right-20 padding-left-20 padding-right-20 padding-top-10 padding-bottom-10 cursor-pointer"
            onClick={() => setOnToggleEdit(true)}
          >
            Edit Task
          </button>
          <button
            className="button-danger margin-top-20 padding-left-20 padding-right-20 padding-top-10 padding-bottom-10 cursor-pointer"
            onClick={() => {setOnDelete(true)}}
          >
            Delete Task
          </button>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className="flex-row content-max-width margin-left-auto margin-right-auto margin-top-30">
        {/* Left Body */}
        <div className="flex margin-left-20 margin-right-20 margin-bottom-20">
          <div className="flex-row">
            <h2 className="margin-bottom-30 margin-right-20">Profile Tasks</h2>
          </div>

          <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
            {listTasks()}
          </div>

          <button
            type="button"
            onClick={() => setCreateProfileTaskModalOpen(true)}
            className="margin-top-20 background-blue padding-left-20 padding-right-20 padding-top-10 padding-bottom-10 cursor-pointer color-white"
          >
            Create Profile Task
          </button>
        </div>
        {/* Right Body */}
        <div className="flex margin-left-20 margin-right-20">
          <h2 className="margin-bottom-30">Task Information</h2>
          {listTaskDetails()}
        </div>
      </div>
      {onDelete && (
        <ConfirmModal
          processing={processing}
          completed={completed}
          closeModal={closeModal}
          operation={deleteProfileTask}
          operationName={"Delete"}
          contentMessage={[
            "Deleting this profile task is ",
            <strong>permanent</strong>,
            " and ",
            <strong>cannot</strong>,
            " be undone. However, tasks that are already assigned will ",
            <strong>not</strong>,
            " be deleted.",
          ]}
        />
      )}
      {onToggleEdit && (
        <EditTaskModal
          closeModal={closeModal}
          selectedTask={selectedTask}
          selectedTaskToExtract={loanConfig.Tasks[selectedTask].ToExtract}
          setOnToggleEdit={setOnToggleEdit}
          updateLoanConfig={updateLoanConfig}
        />
      )}

      <CreateTaskModal
        modalOpen={createProfileTaskModalOpen}
        setModalOpen={setCreateProfileTaskModalOpen}
        updateLoanConfig={updateLoanConfig}
      />
    </>
  );
};

ManageProfileTasksPage.defaultProps = {
  loanConfig: null,
};

ManageProfileTasksPage.propTypes = {
  loanConfig: PropTypes.shape({
    Tasks: PropTypes.shape({}),
  }),
  updateLoanConfig: PropTypes.func.isRequired,
};

export default ManageProfileTasksPage;
